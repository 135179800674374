import React, { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { camelCase } from 'lodash';
import t from 'utils/translationHelper';

import {
  BankingFieldWithValidation, BeneficiaryDto, CountryBankFields, ValidateBeneficiaryRequest,
} from '@alpha/bene-dtos';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import Alert from '../../../../../components/Alert';
import InputAndLabel from '../../../../../components/Inputs/Beneficiary/InputAndLabel';
import BackdropLoader from '../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import formatBankCode from '../../../../../utils/formatBankCode';
import ExtraBankDetails from '../ExtraBankDetails';
import validation from '../formData';

import useStyles from './styles';
import usePaymentMethods from './usePaymentMethods';

interface IProps {
  form: FormikProps<ValidateBeneficiaryRequest>;
  requiresAddress: boolean;
  formValidation: typeof validation;
  selectedBeneficiary?: BeneficiaryDto;
  setRequiresAddress: React.Dispatch<React.SetStateAction<boolean>>;
  setFormValidation: React.Dispatch<React.SetStateAction<any>>;
  sendEmailNotification: boolean;
  setSendEmailNotification: Dispatch<SetStateAction<boolean>>;
  showEmailCheckbox: boolean;
  requiresCorrespondentBankDetails?: boolean;
  setRequiresCorrespondentBankDetails?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean
  setAvailablePaymentMethods: React.Dispatch<React.SetStateAction<CountryBankFields | undefined>>,
  availablePaymentMethods?: CountryBankFields,
}

const PaymentMethods: React.FC<IProps> = ({
  form,
  requiresAddress,
  selectedBeneficiary,
  formValidation,
  setRequiresAddress,
  setFormValidation,
  sendEmailNotification,
  setSendEmailNotification,
  showEmailCheckbox,
  requiresCorrespondentBankDetails,
  setRequiresCorrespondentBankDetails,
  disabled,
  setAvailablePaymentMethods,
  availablePaymentMethods,
}: IProps) => {
  const styles = useStyles();
  const {
    loading,
    tabTitles,
    tab,
    bankCountryCode,
    currencyCode,
    handleDynamicValidationTabChange,
  } = usePaymentMethods(form,
    requiresAddress,
    setRequiresAddress,
    formValidation,
    setFormValidation,
    setAvailablePaymentMethods,
    availablePaymentMethods,
    selectedBeneficiary,
    requiresCorrespondentBankDetails,
    setRequiresCorrespondentBankDetails,
    disabled);

  const translatedTabTitles: string[] = [];
  tabTitles.forEach((title) => {
    translatedTabTitles.push(t(title));
  });

  let translatedTab;
  if (tab) {
    translatedTab = t(tab);
  }
  const handleNationalBankCodeChange = (e: any) => {
    form.setFieldValue('nationalBankCode', e.target.value.replaceAll(/[-\s]/g, ''));
  };

  if (loading) {
    return (
      <BackdropLoader testId="loading" />
    );
  }

  if ((!availablePaymentMethods && !loading) || (!bankCountryCode || !currencyCode)) {
    return null;
  }

  if (!availablePaymentMethods) return null;

  return (
    <div>
      <BaseDrawer.LineBreak />
      <Typography style={{ fontWeight: 'bold', marginTop: '32px' }}>{t('beneficiary_bank_details')}</Typography>
      <StyledTabsWrapper
        testId="dynamicTabs"
        tabTitles={translatedTabTitles}
        tabIndex={translatedTabTitles.indexOf(translatedTab)}
        handleChange={handleDynamicValidationTabChange}
        className={styles.tabs}
        disabledTabs={disabled ? translatedTabTitles.map((r, index) => index) : []}
      />
      <StyledDivider />
      {
        availablePaymentMethods[tab]
          ? (availablePaymentMethods[tab] as BankingFieldWithValidation[]
          ).map((bfwv) => {
            const fieldNameToCamelCase = camelCase(bfwv.field);
            return (
              <div style={{ marginBottom: '20px', marginTop: '20px' }} data-dd-privacy="mask-user-input">
                <InputAndLabel
                  disabled={disabled}
                  label={bfwv.field === 'National Bank Code' ? availablePaymentMethods.nationalBankCodeLabel : bfwv.field}
                  name={fieldNameToCamelCase}
                  id={fieldNameToCamelCase}
                  testId={fieldNameToCamelCase}
                  onChange={bfwv.field === 'National Bank Code' ? handleNationalBankCodeChange : form.handleChange}
                  onBlur={form.handleBlur}
                  value={
                    bfwv.field === 'National Bank Code' ? formatBankCode(form.values.nationalBankCode || '', form.values.bankCountryCode) : (form as any).values[fieldNameToCamelCase] as any
                  }
                  error={
                    (form as any).touched[fieldNameToCamelCase]
                    && Boolean((form as any).errors[fieldNameToCamelCase])
                  }
                  helperText={
                    (form as any).touched[fieldNameToCamelCase]
                    && (form as any).errors[fieldNameToCamelCase]
                  }
                />
              </div>
            );
          }) : (
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
              <Alert
                text={t('no_available_bank_detail_requirements')}
                subText={t('please_select_another_bank_currency')}
                variant="error"
              />
            </div>
          )
      }
      <ExtraBankDetails form={form} required={requiresCorrespondentBankDetails} disabled={disabled} />
      {showEmailCheckbox && (
        <div className={styles.checkbox}>
          <Checkbox
            disabled={disabled}
            testId="send-email-notifcation"
            label={t('send_an_email_notification_to_all_beneficiary_approvers')}
            checked={sendEmailNotification}
            onChange={(event) => { setSendEmailNotification(event.target.checked); }}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
