import React from 'react';
import clsx from 'clsx';

import { TText } from '@alpha/ui-lib/interfaces';
import { Box, IconButton, Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle, IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import {
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from './Alert.styles';

interface IProps {
    text: TText;
    subText?: string;
    variant: 'error' | 'warning' | 'success' | 'info';
    className?: string;
    onClose?: () => void;
    showCloseButton?: boolean;
}

const Alert = ({
  text, subText, variant, className, onClose, showCloseButton,
}: IProps) => {
  const classes = useStyles();

  const iconMapping: Record<string, IconDefinition> = {
    error: faInfoCircle,
    info: faInfoCircle,
    warning: faExclamationTriangle,
    success: faCheckCircle,
  };

  return (
    <Box className={clsx(classes.root, variant, className)}>
      <div className={classes.alertContainer}>
        <div className={classes.headingContainer}>
          <FontAwesomeIcon className="icon" icon={iconMapping[variant] as IconProp} />
          <Typography className="text">{text}</Typography>
        </div>
        {subText && (
          <Typography className="subText" style={{ marginLeft: '24px' }}>{subText}</Typography>
        )}
      </div>
      {showCloseButton
      && (
      <IconButton
        onClick={onClose}
        className={
          classes.closeButton
        }
      >
        <FontAwesomeIcon
          icon={faTimes as IconProp}
          size="sm"
        />
      </IconButton>
      )}
    </Box>
  );
};

export default Alert;
