import React, { useEffect, useState } from 'react';
import Big from 'big.js';
import clsx from 'clsx';
import CreateBeneficiary from 'domain/Beneficiaries/CreateBeneficiary';
import { FormikProps } from 'formik';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { useDispatch, useSelector } from 'react-redux';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { BeneficiaryDto } from '@alpha/bene-dtos';
import { ExecuteTradeResultDto } from '@alpha/fx-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Typography } from '@alpha/ui-lib/ui/external';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../../hooks/useAuthorization';
import useForm from '../../../../../hooks/useForm';
import useLog from '../../../../../hooks/useLog';
import { UserRole } from '../../../../../models/user';
import PaymentsService from '../../../../../services/Payments/payments.service';
import { TStore } from '../../../../../store';
import { initiatePayment } from '../../../../../store/authy/actions';
import { TAuthyState } from '../../../../../store/authy/reducer';
import { formatCurrency, formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import Alert from '../../../../Alert';
import Beneficiaries from '../../../Payments/Beneficiaries';
import validation, { FormikPaymentType, FormikType, initialValues } from '../../../Payments/CreatePaymentDrawer/formData';
import SendPayments from '../../../Payments/SendPayments';
import { TSpotFXRequestForm } from '../formData';
import { TSpotDrawState } from '..';

import useStyles from './index.styles';

export type Section = 'Funding' | 'Beneficiaries' | 'Send';

interface IProps {
  tradeId?: string
  tradeDraftId?: string
  form: FormikProps<TSpotFXRequestForm>
  bookedTrade?: ExecuteTradeResultDto
  handleClose: () => void
  setDrawerState: React.Dispatch<React.SetStateAction<TSpotDrawState>>;
}

const mapSectionToPercentage = (section: Section): '5%' | '50%' | '100%' => {
  switch (section) {
    case 'Beneficiaries':
      return '50%';
    case 'Send':
      return '100%';
    default:
    case 'Funding':
      return '5%';
  }
};

// eslint-disable-next-line max-lines-per-function
const TradePayments: React.FC<IProps> = (props) => {
  const {
    tradeId,
    tradeDraftId,
    form,
    bookedTrade,
    handleClose,
  } = props;

  const styles = useStyles();
  const { logError } = useLog();
  const [section, setSection] = useState<Section>('Beneficiaries');
  const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(true);
  const [createBeneficiaryOpen, setCreateBeneficiaryOpen] = useState<boolean>(false);
  const [refetchBene, setRefetchBene] = useState<number>(0);

  const { isEMoneyDisabled } = useSwitchAccount();

  const { authorized } = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);
  const [beneToUpdate, setBeneToUpdate] = useState<BeneficiaryDto>();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);
  const dispatch = useDispatch();
  const sb = useAlphaSnackbar();
  const formik = useForm(initialValues, validation, (values: FormikType) => {
    if (!values.paymentCurrencyAccount || !values.payments?.length) throw Error('Invalid Payment');
    dispatch(initiatePayment({
      type: 'PAYMENTS',
      approverOwn: authorized,
      paymentIds: values.payments!.map((p: FormikPaymentType) => p.id as string),
    }));
  });

  useEffect(() => {
    if (form.values.defaultBuyCurrencyAccount) {
      formik.setFieldValue('paymentCurrencyAccount', form.values.defaultBuyCurrencyAccount);
      formik.setFieldValue('sellCurrencyAccount', form.values.defaultBuyCurrencyAccount);
    }
  }, [form.values.defaultBuyCurrencyAccount]);

  useEffect(() => {
    setSendEmailNotification(!authorized);
  }, [authorized]);

  useEffect(() => {
    if (
      authyState.type?.type === 'PAYMENTS'
      && authyState.status === 'SUCCESS'
      && !formik.isSubmitting
      && sendEmailNotification
    ) {
      onSubmissionSuccess();
    }
    if (authyState.status === 'SUCCESS' && authyState.type?.type === 'PAYMENTS') {
      handleClose();
    }
  }, [authyState.status, authyState.type]);

  const onSubmissionSuccess = async () => {
    if (formik.values.payments?.length) {
      try {
        const approvers: Approver[] = await PaymentsService.getApprovers(
          formik.values.payments[0].id || '',
        );
        const approverIds: string[] = approvers.flatMap((approver: Approver) => (
          approver.eligible ? approver.userId : []));
        PaymentsService.postGenericPaymentApproverEmail({ approverIds });
      } catch (error) {
        sb.trigger(error?.message || t('there_was_an_error_sending_your_email_notification'), 'error');
        logError({ action: 'Error sending available currencies', error });
      }
    }
  };

  const totalPaymentAmount = formik.values.payments?.reduce((previousValue, currentValue) => ({
    ...currentValue,
    amount: Big(previousValue.amount || 0).plus(currentValue.amount || 0).toNumber(),
  })).amount;

  const handleSetSection = (_section: Section) => setSection(_section);
  return (
    <>
      <div className={styles.trackerContainer}>
        <div style={{ margin: '30px 0' }}>
          <div style={{ position: 'relative', marginBottom: '30px' }} className="stepper">
            <div style={{
              marginLeft: mapSectionToPercentage(section), width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#0C8375', position: 'absolute', zIndex: '1', top: '-5px', left: '-2px',
            }}
            />
            <div style={{
              backgroundColor: '#EFEFEF', height: '5px', borderRadius: '5px', width: '100%', position: 'absolute',
            }}
            />
            <div style={{
              backgroundColor: '#0C8375', height: '5px', borderRadius: '5px', width: mapSectionToPercentage(section), position: 'absolute',
            }}
            />
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '16px',
            }}
            >
              <Typography variant="subtitle1" style={{ marginTop: '16px', opacity: '50%' }} className={clsx(section === 'Funding' && 'active')}>{t('funding')}</Typography>
              <Typography variant="subtitle1" style={{ marginTop: '16px', opacity: '50%' }} className={clsx(section === 'Beneficiaries' && 'active')}>{t('beneficiaries')}</Typography>
              <Typography variant="subtitle1" style={{ marginTop: '16px', opacity: '50%' }} className={clsx(section === 'Send' && 'active')}>{t('send')}</Typography>
            </div>
          </div>
          <BaseDrawer.LineBreak />
        </div>
      </div>
      <div className={styles.statsContainer}>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('sell_currency')}</Typography>
          <Typography className={styles.textStyle}>
            <strong>{`${bookedTrade?.sellCurrency}` || '-'}</strong>
            {` - ${bookedTrade?.sellCurrencyName || '-'}`}
          </Typography>
        </div>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('sell_amount')}</Typography>
          <Typography className={styles.textStyle}>
            <strong>{formatCurrency(bookedTrade?.sellCurrency, bookedTrade?.sellAmount) || '-'}</strong>
          </Typography>
        </div>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>Rate</Typography>
          {bookedTrade?.rate && (
            <Typography className={styles.textStyle}>
              <strong>{formatNumber(bookedTrade?.rate || 0, 4) || '-'}</strong>
              &#124;
              <strong>{formatNumber(1 / bookedTrade?.rate, 4) || '0'}</strong>
            </Typography>
          )}
        </div>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('buy_currency')}</Typography>
          <Typography className={styles.textStyle}>
            <strong>{`${bookedTrade?.buyCurrency}` || '-'}</strong>
            {` - ${bookedTrade?.buyCurrencyName || '-'}`}
          </Typography>
        </div>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('buy_amount')}</Typography>
          <Typography className={styles.textStyle}>
            <strong>{formatCurrency(bookedTrade?.buyCurrency, bookedTrade?.buyAmount) || '-'}</strong>
          </Typography>
        </div>
        <div className={styles.detailContainer}>
          <Typography className={styles.textStyle}>{t('value_date')}</Typography>
          <Typography className={styles.textStyle}>
            {formatIsoDate(bookedTrade?.valueDate) || '-'}
          </Typography>
        </div>
        {bookedTrade && section === 'Beneficiaries' && (
          <div className={styles.amountToAssignContainer}>
            <StyledDivider />
            <div className={styles.amountToAssignText}>
              <Typography className={styles.textStyle}>
                {t('bought_amount_left_to_assign')}
                <FontAwesomeIcon icon={faInfoCircle as IconProp} style={{ marginLeft: '5px' }} />
              </Typography>
              <Typography className={styles.textStyle}>
                <strong>
                  {formatCurrency(
                    bookedTrade?.buyCurrency, bookedTrade?.buyAmount - (totalPaymentAmount || 0),
                  ) || '-'}
                </strong>
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className={styles.standardContainer}>
        <Alert variant="info" text={t('if_the_full_trade_balance_not_remitted')} />
      </div>
      <div className={styles.standardContainer}>
        {
          section === 'Beneficiaries' && formik.values.paymentCurrencyAccount && (
            <Beneficiaries
              {...formik}
              handleSetSection={handleSetSection}
              tradePayments
              tradeBuyAmount={bookedTrade?.buyAmount}
              totalTradePayments={totalPaymentAmount}
              tradeId={tradeId}
              tradeDraftId={tradeDraftId}
              fundingMethod="Account"
              setCreateBeneficiaryOpen={setCreateBeneficiaryOpen}
              setBeneToUpdate={setBeneToUpdate}
              refetchBene={refetchBene}
            />
          )
        }
        {
          section === 'Send' && formik.values.paymentCurrencyAccount && formik.values.payments && (
            <SendPayments
              setFieldValue={formik.setFieldValue}
              values={formik.values}
              handleFormSubmit={formik.handleSubmit}
              handleSetSection={handleSetSection}
              sendEmailNotification={sendEmailNotification}
              setSendEmailNotification={setSendEmailNotification}
              fundingMethod="Account"
            />
          )
        }
      </div>
      <CreateBeneficiary
        firstPartyFlow={isEMoneyDisabled()}
        open={createBeneficiaryOpen}
        draftBeneficiary={beneToUpdate}
        handleDrawerClose={() => {
          setRefetchBene(refetchBene + 1);
          setCreateBeneficiaryOpen(false);
        }}
        updateDrawer
        header={t('update_bene')}
        refetch={setRefetchBene}
      />
    </>
  );
};

export default TradePayments;
