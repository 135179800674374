import env from './env.variables';

const awsExports = {
  Auth: {
    userPoolId: env.REACT_APP_COGNITO.poolId,
    userPoolWebClientId: env.REACT_APP_COGNITO.clientId,
    authenticationFlowType: env.REACT_APP_COGNITO.authenticationFlowType,
    cookieStorage: {
      domain: window.location.href.includes('localhost') ? 'localhost' : '.alpha.co.uk',
      secure: !window.location.href.includes('localhost'),
      path: '/',
      expires: 0.5, // Expires after 12 hours Logic in cognito itself to configure expire time per env
    },
  },
};

export default awsExports;
