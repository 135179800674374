const ignoreSnackbar = (errorContent: string): boolean => {
  if (errorContent.includes('This trade is below your agreed amount limit.')) {
    return true;
  }
  if (errorContent.includes('This trade exceeds your agreed amount limit.')) {
    return true;
  }
  return false;
};

export default ignoreSnackbar;
