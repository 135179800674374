/* eslint-disable no-param-reassign */
/* eslint-disable max-lines-per-function */
import React, { useContext, useEffect, useState } from 'react';
import Alert from 'components/Alert';
import { FormikProps } from 'formik';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { MuiChipsInput } from 'mui-chips-input';
import t from 'utils/translationHelper';

import {
  BeneficiaryDto, BeneficiaryParty, BeneficiaryType,
  CurrencyList, ValidateBeneficiaryRequest,
} from '@alpha/bene-dtos';
import { theme } from '@alpha/ui-lib/theme/themes/default';
import {
  FormControlLabel, Radio, RadioGroup,
} from '@alpha/ui-lib/ui/external';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AutocompleteDropDown, { IOption } from '../../../../../components/AutocompleteDropDown/AutocompleteDropDown';
import InputAndLabel from '../../../../../components/Inputs/Beneficiary/InputAndLabel';
import PaymentContext from '../../../../../components/Molecules/Payments/Beneficiaries/AddPayment/paymentContext';
import { TCountries } from '../../../../../models/countries';

import Address from './Address';
import { useStyles } from './styles';

interface IProps {
  form: FormikProps<ValidateBeneficiaryRequest>;
  requiresAddress: boolean;
  countries: TCountries;
  currencyList: CurrencyList;
  isFirstPartyFlow: boolean;
  selectedBeneficiary?: BeneficiaryDto;
  disabled?: boolean;
}

const PersonalDetails: React.FC<IProps> = ({
  form,
  countries,
  requiresAddress,
  currencyList,
  selectedBeneficiary,
  isFirstPartyFlow,
  disabled: addressOnly,
}: IProps) => {
  const styles = useStyles();
  const [
    currentBankCountryOption,
    setCurrentBankCountryOption,
  ] = useState<IOption>({ name: '', code: '' });

  const [
    currentBankCurrencyOption,
    setCurrentBankCurrencyOption,
  ] = useState<IOption>({ name: '', code: '' });

  const [currencies, setCurrencies] = useState<IOption[]>([]);
  const [bankCountryTextFieldValue, setBankCountryTextFieldValue] = useState<string>('');
  const [bankCurrencyTextFieldValue, setBankCurrencyTextFieldValue] = useState<string>('');

  const paymentContext = useContext(PaymentContext);
  const firstParty = form.values.party === BeneficiaryParty.FIRST;
  const [modifiedAccountHolder, setModifiedAccountHolder] = useState(false);

  const divider: IOption = {
    code: 'divider', disabled: true, name: '',
  };

  const { currentAccount } = useSwitchAccount();

  const { isEMoneyDisabled } = useSwitchAccount();

  useEffect(() => {
    if (form.values.name !== currentAccount.name
      && form.values.party === BeneficiaryParty.FIRST) {
      setModifiedAccountHolder(true);
      form.setFieldValue('partyOverride', true);
    } else {
      setModifiedAccountHolder(false);
      form.setFieldValue('partyOverride', false);
    }
  }, [form.values.name, form.values.party]);

  useEffect(() => {
    const topCurrencyCodes = currencyList.topCurrencies.map((c) => c.code);
    const filterCurrencies = currencyList.currencies.filter(
      (c) => c.code !== 'CNH'
        && !topCurrencyCodes.includes(c.code), // Filter out already in top list

    );
    setCurrencies([...currencyList.topCurrencies, divider, ...filterCurrencies]);
  }, [currencyList.currencies, currencyList.topCurrencies]);

  useEffect(() => {
    if (selectedBeneficiary && currencyList.currencies.length && countries.length) {
      const selectedCurrency = currencyList.currencies.find(
        (c) => c.code === selectedBeneficiary.currencyCode,
      );

      if (selectedCurrency) {
        setCurrentBankCurrencyOption(selectedCurrency);
        setBankCurrencyTextFieldValue(selectedCurrency.name);
      }

      const selectedBankCountry = countries.find(
        (c) => c.code === selectedBeneficiary.bankCountryCode,
      );

      if (selectedBankCountry) {
        setCurrentBankCountryOption(selectedBankCountry);
        setBankCountryTextFieldValue(selectedBankCountry.name);
      }
    }
  }, [currencyList, countries]);

  useEffect(() => {
    if (paymentContext && paymentContext.currencyCode) {
      form.setFieldValue('currencyCode', paymentContext.currencyCode);
      form.values.currencyCode = paymentContext.currencyCode;
      setCurrentBankCurrencyOption({
        name: paymentContext.currency,
        code: paymentContext.currencyCode,
      });
    }
  }, [paymentContext]);

  // Needed for radio button group to update when draft / update bene as doesnt prepopulate otherwise
  const [party, setParty] = useState<BeneficiaryParty | undefined>(BeneficiaryParty.FIRST);
  useEffect(() => {
    setParty(form.values.party);
  }, [form.values.party]);

  return (
    <div className={styles.container}>
      {!isFirstPartyFlow && !isEMoneyDisabled() ? (
        <div className={styles.inputWrapper} style={{ alignItems: 'center' }}>
          <div>
            <Typography variant="subtitle1" style={{ width: '250px' }}>
              {t('1st_or_3rd_payment')}
              {' '}
              <APTooltip
                arrow
                placement="bottom-start"
                title={(
                  <p className={styles.ssiInfo}>
                    <strong>{t('1party')}</strong>
                    {' '}
                    {t('is_a_payment_back_to')}
                    <br />
                    <br />
                    <strong>{t('3party')}</strong>
                    {' '}
                    {t('is_a_payment_to_a_beneficiary')}
                  </p>
                )}
              >
                <span className={styles.infoIcon}>
                  <FontAwesomeIcon size="1x" icon={faInfoCircle as IconProp} />
                </span>
              </APTooltip>
            </Typography>
            <RadioGroup
              row
              value={isFirstPartyFlow ? BeneficiaryParty.FIRST : party}
              onChange={(e) => {
                form.setFieldValue('party', e.target.value);
                if (e.target.value === BeneficiaryParty.FIRST && !isFirstPartyFlow) {
                  form.setFieldValue('name', currentAccount.name);
                  form.setFieldTouched('name', true);
                }
              }}
            >
              <FormControlLabel value={BeneficiaryParty.FIRST} control={<Radio color="primary" />} label={t('1party')} data-testid="bene-first-party" disabled={isFirstPartyFlow || addressOnly} />
              <FormControlLabel value={BeneficiaryParty.THIRD} control={<Radio color="primary" />} label={t('3party')} data-testid="bene-third-party" disabled={isFirstPartyFlow || addressOnly} />

              <Typography color="error" variant="subtitle2">
                {form.touched.name && Boolean(form.errors.party) ? form.errors.party : ''}
              </Typography>

            </RadioGroup>
          </div>

        </div>
      ) : ''}
      {!isFirstPartyFlow && !isEMoneyDisabled() ? (
        <p className={styles.typeInfomation}>
          <strong>{t('note-')}</strong>
          {' '}
          {t('alpha_beneficiary_approval_note')}
        </p>
      ) : ''}
      {
        form.values.party && !firstParty
        && (
          <div className={styles.inputWrapper} style={{ alignItems: 'center' }}>
            <div>
              <APTooltip placement="top-start" arrow title={t('an_individual_beneficiary_type_is_a_personal_bank_account')}>
                <div>
                  <Typography variant="subtitle1">
                    {t('beneficiary_type')}
                    ?
                    {' '}
                    <FontAwesomeIcon
                      icon={faInfoCircle as IconProp}
                      style={{ marginLeft: '8px', color: theme.palette?.primary?.main }}
                    />
                  </Typography>
                </div>
              </APTooltip>
              <RadioGroup
                row
                value={form.values.type}
                onChange={(e) => form.setFieldValue('type', e.target.value)}
              >
                <FormControlLabel value={BeneficiaryType.INDIVIDUAL} control={<Radio color="primary" />} label={t('individual')} disabled={addressOnly} />
                <FormControlLabel value={BeneficiaryType.ENTITY} control={<Radio color="primary" />} label={t('business')} disabled={addressOnly} />
              </RadioGroup>
            </div>
          </div>
        )
      }
      <div className={styles.inputWrapper}>
        <InputAndLabel
          label={t('account_holder')}
          name="name"
          id="name"
          testId="accountHolder"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={isFirstPartyFlow ? currentAccount.name : form.values.name}
          error={
            form.touched.name
            && Boolean(form.errors.name)
          }
          helperText={
            form.touched.name
            && form.errors.name
          }
          warning={t('please_note_the_payment_could_be_rejected_by_the_receiving_bank')}
          disabled={isFirstPartyFlow || addressOnly}
        />
        <InputAndLabel
          label={t('friendly_name')}
          name="friendlyName"
          id="friendlyName"
          testId="friendlyName"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.friendlyName}
          error={
            form.touched.friendlyName
            && Boolean(form.errors.friendlyName)
          }
          helperText={
            form.touched.friendlyName
            && form.errors.friendlyName
          }
          disabled={addressOnly}
        />
      </div>
      <div className={styles.inputWrapper}>
        <div>
          <Typography variant="subtitle1" style={{ marginBottom: '6px' }}>{t('beneficiary_bank_country')}</Typography>
          <AutocompleteDropDown
            name="bankCountryCode"
            testId="bankCountryDropdown"
            options={countries}
            setFieldValue={form.setFieldValue}
            value={form.values.bankCountryCode}
            touched={form.touched.bankCountryCode}
            errors={form.errors?.bankCountryCode}
            currentOption={currentBankCountryOption}
            setCurrentOption={setCurrentBankCountryOption}
            textFieldValue={bankCountryTextFieldValue}
            setTextFieldValue={setBankCountryTextFieldValue}
            disabled={addressOnly}
          />
        </div>
        <div>
          <Typography variant="subtitle1" style={{ marginBottom: '6px' }}>{t('beneficiary_currency')}</Typography>
          <AutocompleteDropDown
            name="currencyCode"
            testId="bankCurrencyDropdown"
            type="CODE-NAME"
            options={currencies}
            setFieldValue={form.setFieldValue}
            value={form.values.currencyCode}
            touched={form.touched.currencyCode}
            errors={form.errors.currencyCode}
            currentOption={currentBankCurrencyOption}
            setCurrentOption={setCurrentBankCurrencyOption}
            textFieldValue={bankCurrencyTextFieldValue}
            setTextFieldValue={setBankCurrencyTextFieldValue}
            disabled={(paymentContext && Boolean(paymentContext.currencyCode)) || addressOnly}
          />
        </div>
      </div>
      <Typography variant="subtitle1">{t('optional_email')}</Typography>
      <MuiChipsInput
        data-testid="bene-email-addresses"
        className={styles.chip}
        value={form.values?.emailAddresses ?? []}
        disabled={addressOnly}
        fullWidth
        onChange={(chips: string[]) => {
          form.setFieldValue('emailAddresses', chips);
        }}
        error={Boolean(form.errors.emailAddresses)}
        helperText={
          form.errors.emailAddresses?.length
            ? form.errors.emailAddresses : ''
        }
        placeholder={t('enter_save_email')}
      />
      {
        requiresAddress || addressOnly ? (
          <Address
            form={form}
            countries={countries}
            disabled={false}
            selectedBeneficiaryCountryCode={form.values.bankCountryCode || selectedBeneficiary?.countryCode}
            addressOnly={addressOnly}
            requiresAddress={requiresAddress}
          />
        ) : null
      }
      {modifiedAccountHolder
        && (
          <Alert
            variant="warning"
            text={t('the_account_holer_has_been_modified_no_longer_matches_name')}
          />
        )}
    </div>
  );
};

export default PersonalDetails;
