import React, { useEffect, useState } from 'react';
import ApprovalDrawer from 'components/Molecules/Payments/Beneficiaries/ApprovalDrawer';
import useAuthorization from 'hooks/useAuthorization';
import { UserRole } from 'models/user';
import t from 'utils/translationHelper';

import { BeneficiaryDto, BeneficiaryStatus } from '@alpha/bene-dtos';
import { Card } from '@alpha/ui-lib/ui/layout/Card';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';

import useReportsPolling, { DownloadType, FileType, PageType } from '../../hooks/useReportsPolling';
import useSwitchAccount from '../../hooks/useSwitchAccount';
import routes from '../../routes.path';
import history from '../../services/history/browserHistory';

import Body from './Body/Body';
import UploadModal from './Body/UploadModal';
import RightAlignedCta from './RightAlignedCTA/RightAlignedCTA';

const Beneficiaries: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [createBeneficiaryOpen, setCreateBeneficiaryOpen] = useState<boolean>(false);
  const [selectedDraftBeneficiary, setSelectedDraftBeneficiary] = useState<BeneficiaryDto>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [multiSelectedBenes,
    setMultiSelectedBenes] = useState<BeneficiaryDto[]>([]);
  const [approvalDrawerOpen,
    setApprovalDrawerOpen] = useState<boolean>(false);
  const [benesToApprove,
    setBenesToApprove] = useState<BeneficiaryDto[]>([]);
  const { authorized: beneInputter } = useAuthorization([[UserRole.BENEFICIARY_INPUTTER]]);
  const { isEMoneyDisabled } = useSwitchAccount();

  const tabs: string[] = [t('approved'), t('pending'), t('rejected'), t('draft')];
  if (!isEMoneyDisabled()) { tabs.push(t('batch_jobs')); }
  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];
  const [open, setOpen] = useState(false);

  const handleTabClick = (value: number) => {
    let queryString = 'approved';
    if (value === 1) {
      queryString = 'pending';
    } else if (value === 2) {
      queryString = 'rejected';
    } else if (value === 3) {
      queryString = 'draft';
    } else if (value === 4) {
      queryString = 'batch';
    }
    history.push(`${routes.beneficiaries}?tab=${queryString}`);
  };

  const beneficiaryApproverOwn = useAuthorization([
    [UserRole.BENEFICAIRY_APPROVER_OWN],
  ]);
  const { executeReportGeneration } = useReportsPolling(PageType.Beneficiaries);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      '',
      undefined,
      undefined,
      DownloadType.Beneficiaries,
      fileType,
    );
  };

  const handleApproveBenes = (benes: BeneficiaryDto[]): void => {
    setBenesToApprove(benes);
    setApprovalDrawerOpen(true);
  };

  const handleSetOpen = () => {
    setOpen(false);
  };

  useEffect(() => {
    setBenesToApprove(multiSelectedBenes);
  }, [multiSelectedBenes]);

  const handleExportButtonClick = () => { handleReportGeneration(FileType.EXCEL); };

  return (
    <APMainLayout
      sticky
      height="165px"
      data-id="beneficiaries"
      title={t('beneficiaries')}
      breadCrumbs={breadcrumbs}
      pageTabs={(
        <StyledTabsWrapper
          testId="beneficiary-tabs"
          tabTitles={tabs}
          tabIndex={selectedTabIndex}
          handleChange={handleTabClick}
        />
      )}
      rightAlignedCta={(
        <RightAlignedCta
          handleOpenCreateBeneficiaryDrawer={() => setCreateBeneficiaryOpen(true)}
          handleExportButtonClick={handleExportButtonClick}
          isBatchJobTab={selectedTabIndex === 4}
          hasBeneInputterRole={beneInputter}
          setOpen={() => setOpen(true)}
        />
      )}
    >
      <div style={{
        minHeight: 'calc(100vh - 310px)',
        paddingTop: '12px',
        paddingBottom: '30px',
        backgroundColor: '#fafafa',
      }}
      >
        <Card>
          <Body
            createBeneficiaryOpen={createBeneficiaryOpen}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            setCreateBeneficiaryOpen={setCreateBeneficiaryOpen}
            setSelectedDraftBeneficiary={setSelectedDraftBeneficiary}
            selectedDraftBeneficiary={selectedDraftBeneficiary}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            multiSelectedBenes={multiSelectedBenes}
            setMultiSelectedBenes={setMultiSelectedBenes}
            handleOpenApprovalDrawer={() => setApprovalDrawerOpen(true)}
            handleApproveBenes={handleApproveBenes}
          />
        </Card>
        {
          (
            benesToApprove.length > 0
            && benesToApprove[0].status === BeneficiaryStatus.SUBMITTED)
          && (
            approvalDrawerOpen
              ? (
                <ApprovalDrawer
                  open={approvalDrawerOpen}
                  onClose={() => {
                    setApprovalDrawerOpen(false);
                    setSelectedIds([]);
                  }}
                  selectedBenes={benesToApprove}
                  canApproveOwn={beneficiaryApproverOwn.authorized}
                />
              ) : null
          )
        }
        <UploadModal open={open} setOpen={handleSetOpen} />
      </div>
    </APMainLayout>
  );
};

export default Beneficiaries;
