import React from 'react';
import ReactCodeInput from 'react-verification-code-input';
import t from 'utils/translationHelper';

import MFAOptionsCard from '../../../../../../components/AuthyComponent/MFAOptionsCard';
import PushNotification from '../../../../../../components/AuthyComponent/PushNotification';
import useAlphaSnackbar from '../../../../../../hooks/useAlphaSnackbar';
import useAuthy from '../../../../../../hooks/useAuthy';
import useLog from '../../../../../../hooks/useLog';
import {
  AuthyPageStates, TAuthyDrawdownType, TAuthyPaymentsType, TAuthyTradeType, TAuthyTransferType,
} from '../../../../../../store/authy/reducer';

interface IProps {
  classes: Record<'header' | 'mfaInputFields' | 'authContainer' | 'info' | 'cta', string>;
  phoneNumber: string;
  handleComplete: (code: number, arid?: string, dlid?: string) => void;
}

const FormBody: React.FC<IProps> = ({
  phoneNumber,
  classes,
  handleComplete,
}: IProps) => {
  const sb = useAlphaSnackbar();
  const { logEvent } = useLog();

  const { triggerMfa, reduxState } = useAuthy();
  const {
    pageState, approvalRequestId, type, dynamicLinkingId,
  } = reduxState;

  const handleCardClick = async (mfaOptionChosen: 'authy' | 'sms' | 'call'): Promise<void> => {
    await triggerMfa(mfaOptionChosen === 'authy' ? undefined : mfaOptionChosen);

    logEvent({ action: `MFA resent to ${mfaOptionChosen}` });
    sb.trigger(
      `${t('verification_code_resent_to')} ${phoneNumber}`,
      'info',
    );
  };

  if (approvalRequestId && dynamicLinkingId
    && (type?.type === 'PAYMENTS'
      || type?.type === 'PAYMENT_APPROVE')
    && pageState === AuthyPageStates.AUTHY) {
    return (
      <PushNotification
        authyType={type.type}
        paymentIds={(type as TAuthyPaymentsType).paymentIds}
        dynamicLinkingId={dynamicLinkingId}
        approvalRequestId={approvalRequestId}
        approveOwn={Boolean(type.approverOwn)}
        firstPartyFlow={(type as TAuthyPaymentsType).firstPartyFlow}
      />
    );
  }

  if (approvalRequestId && dynamicLinkingId
    && (type?.type === 'DRAWDOWN' || (type?.type === 'DRAWDOWN_PAD_APPROVE'))
    && pageState === AuthyPageStates.AUTHY) {
    return (
      <PushNotification
        authyType={type.type}
        paymentIds={(type as TAuthyDrawdownType).paymentIds}
        dynamicLinkingId={dynamicLinkingId}
        approvalRequestId={approvalRequestId}
        approveOwn={Boolean(type.approverOwn)}
        drawdownId={(type as TAuthyDrawdownType).drawdownId}
        firstPartyFlow={(type as TAuthyDrawdownType).firstPartyDrawdown}
      />
    );
  }

  if (approvalRequestId && dynamicLinkingId
    && ((type?.type === 'TRADE') || (type?.type === 'TRADE_PAD_APPROVE'))
    && pageState === AuthyPageStates.AUTHY) {
    return (
      <PushNotification
        authyType={type.type}
        paymentIds={[]}
        tradeId={(type as TAuthyTradeType).id}
        dynamicLinkingId={dynamicLinkingId}
        approvalRequestId={approvalRequestId}
        approveOwn={false}
        tradeType={(type as TAuthyTradeType).type}
      />
    );
  }

  if (approvalRequestId && dynamicLinkingId
    && ((type?.type === 'FORWARDS'))
    && pageState === AuthyPageStates.AUTHY) {
    return (
      <PushNotification
        authyType={type.type}
        paymentIds={[]}
        tradeId={(type as TAuthyTradeType).id}
        dynamicLinkingId={dynamicLinkingId}
        approvalRequestId={approvalRequestId}
        approveOwn={false}
        tradeType={(type as TAuthyTradeType).type}
      />
    );
  }

  if (approvalRequestId && dynamicLinkingId
    && ((type?.type === 'TRANSFER_APPROVE'))
    && pageState === AuthyPageStates.AUTHY) {
    return (
      <PushNotification
        authyType={type.type}
        paymentIds={[]}
        transferId={(type as TAuthyTransferType).transferId}
        dynamicLinkingId={dynamicLinkingId}
        approvalRequestId={approvalRequestId}
        approveOwn={false}
      />
    );
  }

  switch (pageState) {
    case AuthyPageStates.OPTIONS:
      return (
        <MFAOptionsCard
          handleCardClick={handleCardClick}
        />
      );
    default:
      return (
        <div className={classes.mfaInputFields} data-testid="authyInput">
          <ReactCodeInput
            onComplete={(code: number) => handleComplete(code, approvalRequestId, dynamicLinkingId)}
            autoFocus
            className={classes.authContainer}
            fieldWidth={50}
            fieldHeight={50}
            type="number"
          />
        </div>
      );
  }
};

export default FormBody;
