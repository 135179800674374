import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search, { TFilterChipData } from 'components/Organisms/Search';
import SearchTable from 'components/Organisms/SearchTable';
import EmptyTable from 'components/Table/EmptyTable';
import useAuthorization from 'hooks/useAuthorization';
import useReportsPolling, { DownloadType, FileType, PageType } from 'hooks/useReportsPolling';
import i18n from 'i18n/config';
import { startCase } from 'lodash';
import { UserRole } from 'models/user';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapFxTradeStatusDisplay from 'utils/fxTrades/mapFxTradeStatusDisplay';
import mapTradeTypeToFriendlyName from 'utils/fxTrades/mapTradeTypeToFriendlyName';
import t from 'utils/translationHelper';

import { TradeDto, TradeStatus, TradeType } from '@alpha/fx-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, Button } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { Status } from '@alpha/ui-lib/ui/Status';
import {
  faFileExcel, faFilePdf,
} from '@fortawesome/pro-light-svg-icons';

import ActionDropDown from './DisplayTable/ActionDropDown/ActionDropDown';
import PadColumnValue from './TradeDetail/PadColumnValue/PadColumnValue';
import useStyles from './FxTradeTable.styles';
import useFxTradeTable, { TSearchFilterTypes } from './useFxTradeTable';

export type TFilter = {
  value: string,
  field: string,
}

interface IFxTradeTable {
  emptyTitle?: string,
  emptySubtitle?: string,
  testId?: string,
  filters?: TFilter[],
  filtersByField?: TSearchFilterTypes,
  handleRemoveFilterItem?(item: TFilter): void;
  lastUpdatedTime?: string;
}

// eslint-disable-next-line max-lines-per-function
const FxTradeTable: React.FC<IFxTradeTable> = (props: IFxTradeTable) => {
  const {
    emptyTitle,
    emptySubtitle,
    testId,
    filters,
    filtersByField,
    handleRemoveFilterItem,
    lastUpdatedTime,
  } = props;

  const {
    table,
    handleInputChange,
    searchParams,
    handleTableSortClick,
    reloadTableData,
    columns,
    handleTradeNumberClick,
    accountConfiguration,
  } = useFxTradeTable(filtersByField);

  const [filterChips, setFilterChips] = useState<TFilterChipData[]>([]);
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const [openExportMenu, setOpenExportMenu] = useState<null | HTMLElement>(null);
  const { authorized: canCancelOwnTrade } = useAuthorization([[UserRole.SPOT_INPUTTER]]);
  const { authorized: canCancelAnyTrade } = useAuthorization([[UserRole.SPOT]]);
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);

  const classes = useStyles();

  const showCancelTrade = (trade: TradeDto): boolean => {
    if ((trade.submittedBy === userId && canCancelOwnTrade) || canCancelAnyTrade) {
      return true;
    }
    return false;
  };

  const generateTableData = () => (
    table?.items?.items?.records as TradeDto[]
  )?.map((trade) => ({
    ...trade,
    contractNumber: (
      <Button
        disableRipple
        className={classes.tradeTableButton}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          if (handleTradeNumberClick) { handleTradeNumberClick(trade.id); }
        }}
      >
        {trade.contractNumber || '-'}
      </Button>),
    tradeType: t(mapTradeTypeToFriendlyName(trade.type)) || '-',
    tradeDate: formatIsoDate(trade.date),
    accessDate: formatIsoDate(trade.accessDate),
    soldAmount: (
      <div className={classes.amount}>
        <div>{formatNumber(trade.soldAmount || 0, 2)}</div>
        <div className={classes.currencyFlag}><Flag code={trade.soldCurrencyCode} size="sm" showCodeLabel /></div>
      </div>),
    rate: <span className={classes.tableLink}>{trade.rate?.toFixed(4)}</span> || '-',
    buyAmount: (
      <div className={classes.amount}>
        <div>{formatNumber(trade.buyAmount || 0, 2)}</div>
        <div className={classes.currencyFlag}><Flag code={trade.buyCurrencyCode} size="sm" showCodeLabel /></div>
      </div>
    ),
    buyBalance: <div className={classes.amount}>{[formatNumber(trade.buyBalance || 0, 2), trade.buyCurrencyCode].join(' ')}</div>,
    valueDate: formatIsoDate(trade.valueDate),
    tradeStatus: (
      <Status
        className={classes.status}
        variant={mapFxTradeStatusDisplay(trade.status).variant}
      >
        {t(mapFxTradeStatusDisplay(trade.status).text) || '-'}
      </Status>),
    padEligibility: (
      <PadColumnValue
        padEligibleItem={trade}
        handleTradeAuthorisation={handleTradeNumberClick}
      />),
    action: (
      <ActionDropDown
        trade={trade}
        setDisplayMultiSelect={setDisplayMultiSelect}
        displayMultiSelect={displayMultiSelect}
        handleReloadTradesTable={reloadTableData}
        showCancelTrade={showCancelTrade(trade)}
        eMoneyDisabled={accountConfiguration?.eMoneyDisabled}
      />
    ),
  }));

  const mapFiltersToDisplay = (filtersToMap:
    TFilter[]): TFilterChipData[] => filtersToMap.map((filter) => {
    let filterValue = filter.value as string;
    switch (filter.field) {
      case 'tradeStatus':
        filterValue = (startCase(mapFxTradeStatusDisplay(
            filter.value as TradeStatus,
        ).text.toLowerCase()));
        break;
      case 'tradeType':
        filterValue = startCase(mapTradeTypeToFriendlyName(
            filter.value as TradeType,
        ).toLowerCase());
        break;
      case 'tradeStartDate':
      case 'tradeEndDate':
      case 'valueStartDate':
      case 'valueEndDate':
        filterValue = formatIsoDate(filter.value);
        break;
      default:
        filterValue = filter.value;
        break;
    }

    return {
      label: (
        <span>
          <b>
            {t(startCase(filter.field))}
            :
          </b>
          {' '}
          {t(filterValue)}
        </span>),
      value: filter.value,
      field: filter.field,
    };
  });

  useEffect(() => {
    setFilterChips(mapFiltersToDisplay(filters || []));
  }, [filters]);

  useEffect(() => {
    if (lastUpdatedTime) {
      reloadTableData();
    }
  }, [lastUpdatedTime]);

  const handleRemoveFilter = (item: TFilterChipData): void => {
    const { field, value } = item;
    if (handleRemoveFilterItem) { handleRemoveFilterItem({ field, value }); }
  };

  const { executeReportGeneration } = useReportsPolling(PageType.FX);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      '',
      undefined,
      undefined,
      DownloadType.Summary,
      fileType,
      undefined,
      {
        type: fileType,
        sortby: table.sortBy,
        sortorder: table.sortOrder,
        buycurrency: searchParams.queryParams.buycurrency,
        soldcurrency: searchParams.queryParams.soldcurrency,
        tradetype: searchParams.queryParams.type,
        status: searchParams.queryParams.status,
        searchtext: searchParams.queryParams.searchtext,
        tradedatefrom: searchParams.queryParams.tradedatefrom,
        tradedateto: searchParams.queryParams.tradedateto,
        valuedatefrom: searchParams.queryParams.valuedatefrom,
        valuedateto: searchParams.queryParams.valuedateto,
      },
    );
  };

  const menuItems = [
    {
      content: t('export_pdf'),
      icon: faFilePdf,
      onClick: () => handleReportGeneration(FileType.PDF),
      underline: true,
    },
    {
      content: t('export_excel'),
      icon: faFileExcel,
      onClick: () => handleReportGeneration(FileType.EXCEL),
    },
  ];

  return (
    <div data-testid="trades-loader-component" className={classes.tableWrapper}>
      <div style={{ display: 'flex', flexGrow: '1' }}>
        <Search
          testId="search-trades"
          value={table.searchText}
          totalItems={table.items?.items.total || 0}
          placeholder={t('search_by_trade_id_or_reference')}
          onChange={handleInputChange}
          filters={filterChips}
          handleRemoveFilter={handleRemoveFilter}
          style={i18n.language === 'es' ? { width: '370px' } : undefined}
        />
        <div className={classes.exportMenu}>
          <APMenu
            open={Boolean(openExportMenu)}
            setOpen={setOpenExportMenu}
            anchorEl={openExportMenu}
            buttonTitle={t('export')}
          >
            {menuItems.map(
              (item) => <MenuDropdownItem setOpen={setOpenExportMenu}>{item}</MenuDropdownItem>,
            )}
          </APMenu>
        </div>
      </div>
      {
        (table.loading)
        && <Box className={classes.emptyTable}><BackdropLoader testId="loader" /></Box>
      }

      {
        (!table.loading) && ((table.items?.total || 0) > 0 ? (
          <SearchTable
            table={{
              columns,
              data: generateTableData(),
              activeColumn: table.sortBy,
              sortOrder: table.sortOrder,
              dataTestId: testId || '',
              keyColumn: 'id',
              totalRowNumber: table.items?.total || 0,
              handleTableSortClick,
              skip: table.skip,
            }}
            loading={table.loading}
            pagination={{
              handleNext: () => table.handleNextPage(searchParams),
              handlePrevious: () => table.handlePreviousPage(searchParams),
              hasNext: Boolean(table.items?.hasNext),
              hasPrevious: Boolean(table.items?.hasPrevious),
            }}
          />
        ) : (
          <Box className={classes.emptyTable}>
            <EmptyTable
              title={emptyTitle || t('no_fx_trades')}
              subtitle={emptySubtitle || t('you_currently_do_not_have_any_fx_trades')}
              icon={clipboardCross}
            />
          </Box>
        ))
      }
    </div>
  );
};

export default FxTradeTable;
