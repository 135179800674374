import { TBeneBatchId } from 'models/beneficiaries';

import { TransferApprovalResponseDto } from '@alpha/currency-accounts-dtos';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TAuthyType = 'BENEFICIARY' | 'PAYMENTS' |
 'PAYMENT_APPROVE' | 'BENEFICIARY_BATCH' |
  'DRAWDOWN' | 'TRADE' | 'TRADE_PAD_APPROVE' | 'DRAWDOWN_PAD_APPROVE' | 'TRANSFERS' | 'TRANSFER_APPROVE' | 'FORWARDS';
export enum AuthyPageStates {
  AUTHY = 'authy',
  SMS = 'sms',
  call = 'call',
  OPTIONS = 'options',
}
export type TAuthyBeneficiaryType = {
  type: TAuthyType;
  approverOwn: boolean;
  batchId: TBeneBatchId;
  submission?: boolean;
};
export type TAuthyPaymentsType = {
  paymentIds: string[];
  type: TAuthyType;
  approverOwn?: boolean;
  firstPartyFlow?: boolean;
};

export type TAuthyTransferType = {
  transferId: string;
  type: 'TRANSFER_APPROVE' | 'TRANSFERS';
  approverOwn?: boolean;
};

export type TAuthyBeneficiaryBatchType = {
  type: 'BENEFICIARY_BATCH';
  batchId: string;
};

export type TAuthyBeneficiaryBatchUploadType = {
  type: 'BENEFICIARY_BATCH_UPLOAD';
  batchId: string;
};

export type TAuthyDrawdownType = {
  type: 'DRAWDOWN'| 'DRAWDOWN_PAD_APPROVE';
  drawdownId: string;
  approverOwn: boolean;
  paymentIds: string[];
  softToken: boolean;
  firstPartyDrawdown?: boolean;
}

export type TAuthyTradeType = {
  type: 'TRADE' | 'TRADE_PAD_APPROVE' | 'FORWARDS';
  id: string
}

export type TAuthyStatus = 'INITIATED' | 'CODE SUBMITTED' | 'SUCCESS' | 'ERROR';
export type TAuthyState = {
  open: boolean;
  type?: TAuthyBeneficiaryType
    | TAuthyPaymentsType
    | TAuthyBeneficiaryBatchType
    | TAuthyBeneficiaryBatchUploadType
    | TAuthyDrawdownType
    | TAuthyTradeType
    | TAuthyTransferType
  status?: TAuthyStatus;
  extraInfo?: TransferApprovalResponseDto | unknown;
  pageState?: AuthyPageStates;
  phoneNumber?: string;
  approvalRequestId?: string;
  dynamicLinkingId?: string;
};

const initialState: TAuthyState = {
  open: false,
};

export const authySlice = createSlice({
  initialState,
  name: 'authy',
  reducers: {
    reset: (): TAuthyState => initialState,
    initiate: (
      state: TAuthyState,
      { payload }: PayloadAction<TAuthyBeneficiaryType
        | TAuthyPaymentsType
        | TAuthyBeneficiaryBatchType
        | TAuthyBeneficiaryBatchUploadType
        | TAuthyDrawdownType
        | TAuthyTransferType
        | TAuthyTradeType>,
    ) => ({
      ...state,
      open: true,
      type: payload,
      status: 'INITIATED' as TAuthyStatus,
    }),
    closeAuthyPage: () => ({
      open: false,
    }),
    updateStatus: (
      state: TAuthyState,
      { payload }: PayloadAction<TAuthyStatus>,
    ) => ({
      ...state,
      status: payload,
    }),
    updateAuthyExtraInfo: (
      state: TAuthyState,
      { payload }: PayloadAction<unknown>,
    ) => ({
      ...state,
      extraInfo: payload,
    }),
    updatePhoneNumber: (
      state: TAuthyState,
      { payload }: PayloadAction<string>,
    ) => ({
      ...state,
      phoneNumber: payload,
    }),
    updateApprovalRequestId: (
      state: TAuthyState,
      { payload }: PayloadAction<{
        approvalRequestId: string,
         dynamicLinkingId: string} | undefined>,
    ) => ({
      ...state,
      approvalRequestId: payload?.approvalRequestId,
      dynamicLinkingId: payload?.dynamicLinkingId,
    }),
    updateAuthyPageState: (
      state: TAuthyState,
      { payload }: PayloadAction<AuthyPageStates>,
    ) => ({
      ...state,
      pageState: payload,
    }),
  },
});

export const { actions } = authySlice;
