import { useEffect, useState } from 'react';

import {
  ForwardsQuoteRequest, QuoteResponse, TradeDto, TradeSubmissionDto,
} from '@alpha/fx-dtos';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import { TFxBookingState } from '..';

export const useQuoteTradeRate = (
  fixedSide: string,
  validatedTrade?: TradeSubmissionDto | TradeDto | undefined,
  formValues?: any,
) => {
  const { logError, logEvent } = useLog();
  const [timer, setTimer] = useState<number>(100);
  const [timerStartTime, setTimeStartTime] = useState<number>(0);

  const [liveRate, setLiveRate] = useState<QuoteResponse>();
  const [liveRateStatus, setLiveRateStatus] = useState<TFxBookingState>(
    'initiated',
  );

  const sb = useAlphaSnackbar();

  useEffect(() => {
    if (liveRateStatus === 'initiated') {
      setTimeStartTime(Date.now());
      setTimer(100);
    } else if (timer < -4 && liveRateStatus === 'success') {
      setLiveRateStatus('timeout');
    } else if (liveRateStatus === 'success') {
      const interval = setInterval(() => {
        const msElipised = (Date.now() - timerStartTime);
        setTimer(100 - (msElipised / 100));
      }, 200);

      return () => {
        clearInterval(interval);
      };
    } else if (liveRateStatus === 'initiatedBookTrade') {
      setTimeStartTime(0);
      setTimer(0);
    }

    return () => {};
  }, [timer, setTimer, liveRateStatus]);

  const quoteLiveRate = async () => {
    if (formValues && validatedTrade) {
      try {
        setLiveRateStatus('loading');
        const forwardsTradeRequest: ForwardsQuoteRequest = {
          buyCurrency: formValues.buyCurrencyCode,
          sellCurrency: formValues.sellCurrencyCode,
          fixedSide,
          amount: formValues.fixedAmount,
          valueDate: formValues.valueDate,
          tradeId: formValues.tradeId,
          debitCurrencyAccount: formValues.selectedSellCurrencyAccount?.id,
          creditCurrencyAccount: formValues.selectedBuyCurrencyAccount?.id,
          initialMarginSide: formValues.initialMarginPercentage === 0 ? 'Sell' : 'Buy', // check
          forwardReason: formValues.forwardReason,
        };
        const quoteResult = await FXTradeService.postForwardsQuote(forwardsTradeRequest);
        setLiveRate(quoteResult);
        setLiveRateStatus('initiated');
        await new Promise((r) => setTimeout(r, 10)); // Give some time for reset progress bar
        setLiveRateStatus('success');
        logEvent({ action: 'Successfully quote live rate' });
      } catch (error) {
        const errorMessage = error?.response?.data?.error || error.message || 'There was an error retrieving your live rate';
        sb.trigger(errorMessage);
        logError({ action: 'Error quoting live rate', error });
        setLiveRateStatus('failed');
      }
    } else {
      setLiveRate(undefined);
      setLiveRateStatus('failed');
    }
  };

  return {
    timer,
    setTimer,
    liveRate,
    setLiveRate,
    liveRateStatus,
    setLiveRateStatus,
    quoteLiveRate,
  };
};

export default useQuoteTradeRate;
