import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  dropdownContainer: {
    position: 'relative',
  },
  dropdown: (props: { backgroundColor?: string }) => ({
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#f7f7f7',
    '& .MuiSelect-root': {
      backgroundColor: props.backgroundColor ? props.backgroundColor : '#f7f7f7',
      height: '44px',
      borderRadius: '5px',
      padding: '0px 16px 0px 16px',
      marginBottom: '6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      right: '7px',
    },
  }),
  dropdownList: {
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px',
      '& > li:first-of-type': { // Using first of type here as the placeholder will always be positioned as the first item
        display: 'none',
      },
      '& > *': {
        height: '49px',
      },
    },
  },
  placeholder: (props: any) => ({
    color: '#34343473',
    fontWeight: 400,
    fontSize: props.fx ? '16px' : '14px',
    overflow: props.fx ? 'hidden' : 'unset',
    whiteSpace: props.fx ? 'nowrap' : 'unset',
    textOverflow: props.fx ? 'ellipsis' : 'unset',
  }),
  red: {
    color: '#b95656 !important',
  },
  error: {
    position: 'absolute',
    fontSize: '12px !important',
  },
}), { name: 'InterAccountTransferDropdown' });
export default useStyles;
