import React, { useCallback, useEffect, useState } from 'react';
import Warning from 'assets/warning.svg';
import clsx from 'clsx';
import InputAndLabel from 'components/Inputs/Beneficiary/InputAndLabel';
import DrawerBackdropLoader from 'components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import PaymentContext from 'components/Molecules/Payments/Beneficiaries/AddPayment/paymentContext';
import CreateBeneficiary from 'domain/Beneficiaries/CreateBeneficiary';
import BeneficiaryDropdownContainer from 'domain/Transactions/ManualPayments/Body/PaymentType/ManualPayment/BeneficiaryDropdown/BeneficiaryDropdownContainer';
import { FormikProps } from 'formik';
import Authorization from 'hocs/Authorization';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { debounce } from 'lodash';
import { UserRole } from 'models/user';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import PaymentsService from 'services/Payments/payments.service';
import timer from 'utils/timer';
import t from 'utils/translationHelper';

import { UserAcknowledgmentRequest, UserAcknowledgmentType } from '@alpha/auth-dtos';
import {
  ExecuteTradeRequest,
  ExecuteTradeResultDto,
  TradeDto,
  TradeFundingMethod,
  TradeRequest,
  TradeSubmissionDto,
} from '@alpha/fx-dtos';
import {
  PaymentDto, PaymentStatus,
} from '@alpha/payments-dtos';
import { Alert } from '@alpha/ui-lib/ui/Alert';
import { IconButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import {
  Box, Input, InputLabel, Typography,
} from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { ConfirmationModal } from '@alpha/ui-lib/ui/Modal/ConfirmationModal';
import { Status } from '@alpha/ui-lib/ui/Status';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { faArrowUpArrowDown, faTimes } from '@fortawesome/pro-regular-svg-icons';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import AuthService from '../../../../../services/Auth/auth.service';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import { TStore } from '../../../../../store';
import { initiatePayment, initiateTrade } from '../../../../../store/authy/actions';
import { TAuthyState } from '../../../../../store/authy/reducer';
import AutocompleteDropDown from '../../../../AutocompleteDropDown/AutocompleteDropDown';
import Rate from '../../../../InterAccountTransfer/Rate/Rate';
import LinearProgress from '../../../../LinearProgress/LinearProgress';
import BackdropLoader from '../../../Loaders/BackdropLoader/BackdropLoader';
import { TSpotFXRequestForm } from '../formData';
import { TSpotDrawState } from '..';

import Actions from './Actions';
import DuplicatedTradeModal from './DuplicatedTradeModal';
import FundingMethod from './FundingMethod';
import { useStyles } from './index.styles';
import { useInputSpotTrade } from './useInputSpotTrade';
import useQuoteTradeRate from './useQuoteTradeRate';

interface IProps {
  form: FormikProps<TSpotFXRequestForm>;
  handleBlur?: (event: any) => void;
  setDrawerState: React.Dispatch<React.SetStateAction<TSpotDrawState>>;
  setBookedTrade: React.Dispatch<React.SetStateAction<ExecuteTradeResultDto | undefined>>;
  padAllowed?: boolean;
  firstPartyFlow: boolean;
  validatedTrade?: TradeSubmissionDto | TradeDto;
  setValidatedTrade: React.Dispatch<React.SetStateAction<
    TradeSubmissionDto | TradeDto | undefined>>;
  tradeDraft?: TradeDto;
  handleClose: () => void;
  submittingDraft: boolean;
  isTransfer: boolean;
}

// eslint-disable-next-line max-lines-per-function
const InputSpotTrade: React.FC<IProps> = ({
  form,
  handleBlur,
  setDrawerState,
  setBookedTrade,
  padAllowed = false,
  firstPartyFlow,
  validatedTrade,
  setValidatedTrade,
  tradeDraft,
  handleClose,
  submittingDraft,
  isTransfer = false,
}) => {
  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();
  const { logError, logEvent } = useLog();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const [mfaVerified, setMfaVerified] = useState<boolean>(false);
  const [openClearAllConfirmation, setOpenClearAllConfirmation] = useState(false);
  const [beneficiaryDrawerOpen, setBeneficiaryDrawerOpen] = useState<boolean>(false);
  const { isEMoneyDisabled } = useSwitchAccount();

  const {
    allowedSellCurrenciesOption,
    allowedBuyCurrenciesOption,
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
    sellCurrencyTextFieldValue,
    setSellCurrencyTextFieldValue,
    buyCurrencyTextFieldValue,
    setBuyCurrencyTextFieldValue,
    fixedSide,
    setFixedSide,
    indicativeRate,
    pageLoading,
    setPageLoading,
    rateLoading,
    updateCounterSideAmount,
    buyStartAdornment,
    sellStartAdornment,
    insufficientFunds,
    availableSpotDates,
    valueDatesLoading,
    swapCurrencies,
    handleClearAll,
  } = useInputSpotTrade(form);

  const {
    timer: timerForRate,
    setTimer,
    liveRate,
    setLiveRate,
    liveRateStatus,
    setLiveRateStatus,
    quoteLiveRate,
  } = useQuoteTradeRate(validatedTrade);

  const [duplicateWarningModal, setDuplicateWarningModal] = useState<boolean>(false);
  const [fixedSideIndicator, setFixedSideIndicator] = useState<'active' | 'inactive'>('inactive');
  const tooltipText = t('please_input_when_rate_is_loaded');
  const [loading, setLoading] = useState(false);

  const handleTradeMfa = (tradeID: string) => {
    dispatch(initiateTrade({
      type: 'TRADE',
      id: tradeID,
    }));
  };

  const handleInputBlur = (event: any) => {
    try {
      if (event.target.value) {
        event.target.value = parseFloat(event.target.value.replace(/,/g, '')).toFixed(2);
        if (handleBlur) handleBlur(event);
      }
    } catch {
      if (handleBlur) handleBlur(event);
    }
  };

  const updateBuyAmountChanges = useCallback(
    debounce((valueStr: string, rate: number, buyCcy: string, sellCcy: string) => {
      (async () => {
        const value = parseFloat(valueStr.toString().replace(/,/g, '')) || 0.00;
        await form.setFieldTouched('buyAmount', true);

        if (sellCcy && buyCcy) {
          updateCounterSideAmount('buy', value, rate, buyCcy, sellCcy);
        }

        await form.setFieldValue('fixedAmount', value.toFixed(2));
        form.setFieldValue('fixedCurrencyCode', buyCcy);
        setFixedSide('buy');
      })();
    }, 200), [],
  );

  const updateSellAmountChanges = useCallback(
    debounce((valueStr: string, rate: number, buyCcy: string, sellCcy: string) => {
      (async () => {
        const value = parseFloat(valueStr.toString().replace(/,/g, '')) || 0.00;
        form.setFieldTouched('sellAmount', true);

        if (sellCcy && buyCcy) { updateCounterSideAmount('sell', value, rate, buyCcy, sellCcy); }

        form.setFieldValue('fixedAmount', value.toFixed(2));
        form.setFieldValue('fixedCurrencyCode', sellCcy);
        setFixedSide('sell');
      })();
    }, 200), [],
  );

  const handleBuyAmountChange = async (event: any) => {
    setFixedSideIndicator('active');
    await form.setFieldValue('buyAmount', event.target.value, true);
    updateBuyAmountChanges(event.target.value, indicativeRate?.rate || 0,
      form.values.buyCurrencyCode, form.values.sellCurrencyCode);
  };

  const handleSellAmountChange = async (event: any) => {
    setFixedSideIndicator('active');
    await form.setFieldValue('sellAmount', event.target.value, true);
    updateSellAmountChanges(event.target.value, indicativeRate?.rate || 0,
      form.values.buyCurrencyCode, form.values.sellCurrencyCode);
  };

  const handleValidateTrade = (): void => {
    validateTrade(form.values);
  };

  useEffect(() => {
    const rate = liveRate ? liveRate.rate : indicativeRate?.rate;
    if (form.values.draftInitiated) {
      setFixedSide(form.values.fixedCurrencyCode === form.values.buyCurrencyCode ? 'buy' : 'sell');
      setFixedSideIndicator('active');
    }
    if (liveRate && liveRate.rate) {
      form.setFieldValue('quoteRate', liveRate.rate);
    }

    if (liveRate && liveRate.buyAmount && liveRate.sellAmount) {
      // use buy amount / sell amount from quote endpoint
      form.setFieldValue('buyAmount', liveRate.buyAmount, true);
      form.setFieldValue('sellAmount', liveRate.sellAmount, true);
      return;
    }

    if (rate !== 0 && (form.values.sellCurrencyCode && form.values.buyCurrencyCode)) {
      // Indicative rate & if amount not returned
      if (fixedSide === 'buy' && form.touched.buyAmount) {
        const value = +form.values.buyAmount.toString().replace(/,/g, '');
        updateCounterSideAmount('buy', value, rate || 0,
          form.values.buyCurrencyCode, form.values.sellCurrencyCode);
      } else if (fixedSide === 'sell' && form.touched.sellAmount) {
        const value = +form.values.sellAmount.toString().replace(/,/g, '');
        updateCounterSideAmount('sell', value, rate || 0,
          form.values.buyCurrencyCode, form.values.sellCurrencyCode);
      }
    }
  }, [indicativeRate, liveRate]);

  const validateTrade = async (requestForm: TSpotFXRequestForm) => {
    setPageLoading(true);
    try {
      const tradeRequest: TradeRequest = {
        fundingMethod: requestForm.fundingMethod,
        sellCurrencyCode: requestForm.sellCurrencyCode,
        buyCurrencyCode: requestForm.buyCurrencyCode,
        fixedCurrencyCode: requestForm.fixedCurrencyCode,
        fixedAmount: requestForm.fixedAmount,
        valueDate: requestForm.valueDate,
      };

      if (!tradeDraft) {
        const validatedTradeResult = await FXTradeService.postValidateTradeRequest(tradeRequest);
        setValidatedTrade(validatedTradeResult);

        setPageLoading(false);
        if (submittingDraft) {
          handleClose();
          sb.trigger(t('draft_spot_trade_submitted_successfully'), 'success');
          logEvent({ action: 'Successfully submitted draft spot trade' });
        } else if (validatedTradeResult.potentialDuplicates.length > 0) {
          setDuplicateWarningModal(true);
        } else {
          handleTradeMfa(validatedTradeResult.id);
        }
      } else {
        await FXTradeService.updateTrade(
          tradeDraft.id,
          {
            fundingMethod: requestForm.fundingMethod,
            valueDate: requestForm.valueDate,
          },
        );

        setPageLoading(false);
        setValidatedTrade(tradeDraft);
        handleTradeMfa(tradeDraft.id);
      }
    } catch (e) {
      sb.trigger(e.response?.data.error
        || e.message
        || t('sorry_we_could_not_validate_this_trade'), 'error');

      logError({ action: 'Error validating trade', error: e });
      setValidatedTrade(undefined);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (liveRateStatus === 'failed') {
      setValidatedTrade(undefined);
      setMfaVerified(false);
    }
  }, [liveRateStatus]);

  useEffect(() => {
    if (authyState.status === 'SUCCESS' && authyState.type?.type === 'TRADE'
    ) {
      if (liveRateStatus !== 'loading') {
        handleSuccessfulAuthy();
      }
    }

    if (authyState.status === 'ERROR' && authyState.type?.type === 'TRADE') {
      setValidatedTrade(undefined);
      setMfaVerified(false);
    }

    if (authyState.status === undefined && authyState.type?.type === undefined && !mfaVerified) {
      setValidatedTrade(undefined);
    }
  }, [authyState.status]);

  useEffect(() => {
    if (authyState.status === 'SUCCESS' && authyState.type?.type === 'PAYMENTS' && firstPartyFlow) {
      form.setFieldValue('paymentSubmitted', true);
      setLoading(false);
      sb.trigger(t('spot_trade_has_been_booked_successfully'), 'success');
      setDrawerState('ConfirmTrade');
    }
  }, [authyState.status, authyState.type]);

  const handleSuccessfulAuthy = () => {
    setMfaVerified(true);
    setTimeout(() => { // Timeout to wait for trade status updated in DB
      setLiveRateStatus('loading');
      quoteLiveRate();
    }, 3000);
  };

  const handleBookTrade = async (): Promise<void> => {
    try {
      setLiveRateStatus('initiatedBookTrade');
      let executeTradeRequest: ExecuteTradeRequest;
      if (firstPartyFlow) {
        executeTradeRequest = {
          instructionId: String(liveRate?.instructionId),
          tradeDraftId: String(validatedTrade?.id),
          tradePayment: {
            beneficiaryId: form.values.beneficiary?.id ?? '',
            sellCurrencyAccountId: form.values.defaultSellCurrencyAccount?.id ?? '',
            sellCurrencyCode: form.values?.sellCurrencyCode,
            debitingCurrencyAccountId: form.values.defaultBuyCurrencyAccount?.id ?? '',
            debitingCurrencyCode: form.values.defaultBuyCurrencyAccount?.currencyCode ?? '',
            reference: form.values.reference,
            amount: parseFloat(form.values.buyAmount.toString().replace(/,/g, '')),
          },
        };
      } else {
        executeTradeRequest = {
          instructionId: String(liveRate?.instructionId),
          tradeDraftId: String(validatedTrade?.id),
        };
      }

      const bookingResult = await FXTradeService.postExecuteTradeBooking(executeTradeRequest);

      if (firstPartyFlow && bookingResult?.payment.id) {
        setLoading(true);
        pollSinglePayment(6, 1000, bookingResult?.payment?.id);
      }
      setLiveRateStatus('successBookTrade');
      if (firstPartyFlow) {
        setBookedTrade(bookingResult.trade);
      } else {
        setBookedTrade(bookingResult);
      }
      if (!firstPartyFlow) { sb.trigger(t('spot_trade_has_been_booked_successfully'), 'success'); }
      logEvent({ action: 'Successfully booked spot trade' });
      logEvent({
        action: 'Book Trade Successfully',
        detail: {
          buyCurrency: bookingResult.buyCurrency,
          sellCurrency: bookingResult.sellCurrency,
        },
      });
      if (!firstPartyFlow) { setDrawerState('ConfirmTrade'); }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || error.message || t('there_was_an_error_with_your_booking_please_request_new_live_rate');
      sb.trigger(errorMessage, 'error');
      logError({ action: 'Error booking trade', error });
      setLiveRateStatus('failedBookTrade');
    }
  };

  const rateType = () => (liveRateStatus === 'timeout'
    ? 'expired'
    : 'live');

  const handleAcknowledgeDuplicate = () => {
    setDuplicateWarningModal(false);
    if (validatedTrade) {
      const acknowledgementRequest: UserAcknowledgmentRequest = {
        itemId: validatedTrade?.id,
        type: UserAcknowledgmentType.TRADE,
        content: {
          tradeId: validatedTrade?.id,
          potentialDuplicates: (validatedTrade as TradeSubmissionDto)?.potentialDuplicates,
          message: 'Spot trade potentially duplicated.',
        },
      };
      AuthService.postUserAcknowlegement(acknowledgementRequest);

      handleTradeMfa(validatedTrade.id);
    }
  };

  const setFormData = (id: string) => {
    const payment = { ...form.values, id };
    form.setFieldValue('payments', [payment]);
    logEvent({ action: 'Successfully added payment' });
  };

  const loadPaymentData = async (id: string): Promise<PaymentDto> => {
    try {
      setLoading(true);
      const result = await PaymentsService.getSinglePayment(id);
      return result;
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_payment'));
      logError({ action: 'Error loading payment', error });
      return [];
    }
  };

  const pollSinglePayment = async (retry = 6, delay = 1000, id: string) => {
    const statusToPoll = [PaymentStatus.VALIDATED, PaymentStatus.ERRORED, PaymentStatus.INVALID];
    setLoading(true);
    const record = await loadPaymentData(id);

    // eslint-disable-next-line max-len
    if (retry === 0 || record.status === PaymentStatus.INVALID || record.status === PaymentStatus.ERRORED) {
      if (record.errors.length) {
        record.errors.forEach((error) => {
          sb.trigger(
            `${error}`,
            'error',
            {
              persist: true,
              action: (key: string) => (
                <IconButton
                  icon={faTimes}
                  onClick={() => {
                    sb.close(key);
                  }}
                  style={{ color: '#fff', fontSize: '12px', display: 'inline-block' }}
                />
              ),
            },
          );

          logError({ action: `Error validating single payment - ${error}` });
        });
      } else {
        sb.trigger(`${t('there_was_an_error_validating_your_payment')}.`, 'error');
      }

      logError({ action: 'Error validating single payment' });
      setLoading(false);
      return;
    }
    await timer(delay);
    // eslint-disable-next-line max-len
    if (!statusToPoll.includes(record.status)) {
      pollSinglePayment(retry - 1, 1000, id);
    }
    // eslint-disable-next-line max-len

    if (record.status === PaymentStatus.VALIDATED) {
      dispatch(initiatePayment({
        type: 'PAYMENTS',
        approverOwn: true, // default set to true for first party clients
        paymentIds: [record.id],
        firstPartyFlow: true,
      }));
      setFormData(id);
    }
  };

  if (loading) return <DrawerBackdropLoader display text={t('booking_spot_trade')} />;

  return (
    <Box className={styles.form}>
      {pageLoading && (<BackdropLoader testId="backdrop-loader" />)}
      {!pageLoading && (
        <>
          <Box display="flex" justifyContent="space-between" flexDirection="column" flexGrow={1}>
            {/* Sell */}
            <Box className={clsx(styles.inputLabel)}>
              {!(validatedTrade && mfaVerified) ? (
                <>
                  <InputLabel htmlFor="sellCurrency">{t('sell_currency')}</InputLabel>
                  <AutocompleteDropDown
                    name="sellCurrencyCode"
                    testId="bankCurrencyDropdown"
                    type="CODE-NAME"
                    options={selectedBuyCurrencyOption ? allowedSellCurrenciesOption.filter(
                      (item) => item.code !== selectedBuyCurrencyOption?.code,
                    ) : allowedSellCurrenciesOption}
                    setFieldValue={form.setFieldValue}
                    value={form.values.sellCurrencyCode}
                    touched={form.touched.sellCurrencyCode}
                    currentOption={selectedSellCurrencyOption}
                    setCurrentOption={setSelectedSellCurrencyOption}
                    textFieldValue={sellCurrencyTextFieldValue}
                    setTextFieldValue={setSellCurrencyTextFieldValue}
                    disabled={Boolean(validatedTrade) || form.values.draftInitiated}
                    placeholder={t('select_sell_currency')}
                  />
                </>
              ) : (
                <>
                  {' '}
                  <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center' }}>
                    {t('i_will_sell')}
                    {' '}
                    <Box className={styles.imageHolder}>
                      {form.values.sellCurrencyCode && <Flag code={form.values.sellCurrencyCode} size="md" />}
                    </Box>
                  </Typography>
                </>
              )}
            </Box>
            <Box className={clsx(styles.inputAmount, styles.root)}>
              <Box className={styles.willDebit}>
                {!validatedTrade && <InputLabel htmlFor="sellAmount">{t('sell_amount')}</InputLabel>}
                <Box className={styles.amountInput} gridGap={10}>
                  <APTooltip
                    placement="bottom-start"
                    title={rateLoading ? tooltipText : ''}
                  >
                    <NumberFormat
                      thousandSeparator
                      className={clsx(
                        styles.red,
                      )}
                      id="sellAmount"
                      name="sellAmount"
                      value={form.values.sellAmount ? form.values.sellAmount : ''}
                      disabled={Boolean(validatedTrade)
                        || rateLoading
                        || form.values.draftInitiated}
                      onChange={handleSellAmountChange}
                      onBlurCapture={handleInputBlur}
                      disableUnderline
                      autoComplete="off"
                      placeholder="0.00"
                      startAdornment={`${sellStartAdornment}`}
                      type="text"
                      customInput={Input}
                      inputProps={{
                        'data-testid': 'sellAmount',
                      }}
                      style={{ flexGrow: 1 }}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </APTooltip>
                  {!validatedTrade && (
                    <Status
                      className={clsx(
                        {
                          [styles.fixedIndicatorHidden]: fixedSideIndicator === 'inactive',
                          [styles.fixedIndicatorInactive]: fixedSideIndicator === 'active' && fixedSide !== 'sell',
                          [styles.fixedIndicatorActive]: fixedSideIndicator === 'active' && fixedSide === 'sell',
                        },
                      )}
                    >
                      {t('fixed')}
                    </Status>
                  )}
                </Box>
                <InputLabel className={clsx(styles.error, styles.red)}>
                  {form.touched.sellAmount ? form.errors.sellAmount : ''}
                </InputLabel>
              </Box>
            </Box>
            <Box className={clsx(styles.rateContainer)}>
              <BaseDrawer.LineBreak />
              {!validatedTrade && (
                <>
                  <Rate
                    type={!validatedTrade ? 'indicative' : rateType()}
                    creditingCurrency={selectedSellCurrencyOption?.code || ''}
                    debitingCurrency={selectedBuyCurrencyOption?.code || ''}
                    rate={indicativeRate ? indicativeRate.rate : 0.00}
                    loading={rateLoading}
                  />
                  <div style={{
                    float: 'right',
                    marginLeft: 'auto',
                    marginRight: '24px',
                  }}
                  >
                    <APTooltip title={t('swap_sides')}>
                      <div>
                        {(form.values.buyCurrencyCode || form.values.sellCurrencyCode) && (
                          <IconButton
                            title="Swap"
                            icon={faArrowUpArrowDown}
                            size="medium"
                            disabled={rateLoading}
                            onClick={() => {
                              swapCurrencies();
                            }}
                            className={styles.swapButton}
                          />
                        )}
                      </div>
                    </APTooltip>
                  </div>
                </>
              )}
              {validatedTrade && (
                <Rate
                  type={liveRate ? rateType() : 'indicative'}
                  creditingCurrency={selectedSellCurrencyOption?.code || ''}
                  debitingCurrency={selectedBuyCurrencyOption?.code || ''}
                  rate={liveRate?.rate || indicativeRate?.rate || 0.00}
                  loading={liveRateStatus === 'loading' || liveRateStatus === 'initiated'}
                />
              )}
            </Box>

            {/* Buy */}
            <Box className={clsx(styles.inputLabel)}>
              {!(validatedTrade && mfaVerified) ? (
                <>
                  <InputLabel htmlFor="buyCurrency">{t('buy_currency')}</InputLabel>
                  <AutocompleteDropDown
                    name="buyCurrencyCode"
                    testId="bankCurrencyDropdown"
                    type="CODE-NAME"
                    options={selectedBuyCurrencyOption ? allowedBuyCurrenciesOption.filter(
                      (item) => item.code !== selectedSellCurrencyOption.code,
                    ) : allowedBuyCurrenciesOption}
                    setFieldValue={form.setFieldValue}
                    value={form.values.buyCurrencyCode}
                    touched={form.touched.buyCurrencyCode}
                    errors={form.errors.buyCurrencyCode}
                    currentOption={selectedBuyCurrencyOption}
                    setCurrentOption={setSelectedBuyCurrencyOption}
                    textFieldValue={buyCurrencyTextFieldValue}
                    setTextFieldValue={setBuyCurrencyTextFieldValue}
                    disabled={Boolean(validatedTrade) || form.values.draftInitiated}
                    placeholder={t('select_buy_currency')}
                  />
                </>
              ) : (
                <>
                  <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center' }}>
                    {t('i_will_buy')}
                    <Box className={styles.imageHolder}>
                      {form.values.buyCurrencyCode && <Flag code={form.values.buyCurrencyCode} size="md" />}
                    </Box>
                  </Typography>
                </>
              )}
            </Box>
            <Box className={clsx(styles.inputAmount, styles.root)}>
              <Box className={styles.willDebit}>
                {!(validatedTrade && mfaVerified)
                  && <InputLabel htmlFor="buyAmount">{t('buy_amount')}</InputLabel>}
                <Box className={styles.amountInput} gridGap={10}>
                  <APTooltip
                    placement="bottom-start"
                    title={rateLoading ? tooltipText : ''}
                  >
                    <NumberFormat
                      thousandSeparator
                      customInput={Input}
                      className={styles.green}
                      id="buyAmount"
                      name="buyAmount"
                      value={form.values.buyAmount ? form.values.buyAmount : ''}
                      disabled={Boolean(validatedTrade)
                        || rateLoading
                        || form.values.draftInitiated}
                      onChange={handleBuyAmountChange}
                      onBlurCapture={handleInputBlur}
                      disableUnderline
                      autoComplete="off"
                      placeholder="0.00"
                      startAdornment={`${buyStartAdornment}`}
                      type="text"
                      inputProps={{
                        'data-testid': 'buyAmount',
                      }}
                      style={{ flexGrow: 1 }}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </APTooltip>
                  {!validatedTrade && (
                    <Status
                      className={clsx(
                        {
                          [styles.fixedIndicatorHidden]: fixedSideIndicator === 'inactive',
                          [styles.fixedIndicatorInactive]: fixedSideIndicator === 'active' && fixedSide !== 'buy',
                          [styles.fixedIndicatorActive]: fixedSideIndicator === 'active' && fixedSide === 'buy',
                        },
                      )}
                    >
                      {t('fixed')}
                    </Status>
                  )}
                </Box>
                <InputLabel className={clsx(styles.error, styles.red)}>
                  {form.touched.buyAmount ? form.errors.buyAmount : ''}
                </InputLabel>
              </Box>
            </Box>

            {(validatedTrade && mfaVerified) && (
              <Box style={{ padding: '4px 20px' }}>
                <LinearProgress
                  variant="determinate"
                  value={timerForRate}
                  classes={{
                    bar: clsx({ [styles.progressBarNoAnimation]: liveRateStatus === 'initiated' || liveRateStatus === 'initiatedBookTrade' }),
                  }}
                />
              </Box>
            )}

            <FundingMethod
              firstPartyFlow={firstPartyFlow}
              show={form.values.buyAmount !== 0 || form.values.sellAmount !== 0}
              padAllowed={padAllowed}
              form={form}
              earliestValueDate={liveRate ? liveRate?.valueDate : indicativeRate?.valueDate}
              editable={!(validatedTrade && mfaVerified)}
              insufficientFunds={insufficientFunds()}
              availableValueDates={availableSpotDates}
              valueDatesLoading={valueDatesLoading}
              isTransfer={isTransfer}
            />

            {firstPartyFlow && !mfaVerified && (
              <Box style={{ padding: '10px 30px' }}>
                <div style={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px',
                }}
                >
                  <Typography variant="subtitle1">{t('beneficiary')}</Typography>
                  <Authorization requiredRoles={[[UserRole.BENEFICIARY_INPUTTER]]}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: '#1E8777', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => setBeneficiaryDrawerOpen(true)}
                    >
                      {t('create_new?')}
                    </Typography>
                  </Authorization>
                </div>
                <BeneficiaryDropdownContainer
                  currencyCode={form.values.defaultBuyCurrencyAccount?.currencyCode || ''}
                  beneficiary={form.values.beneficiary}
                  setFieldValue={form.setFieldValue}
                />
                <div style={{ marginTop: '20px' }}>
                  <InputAndLabel
                    disabled={!form.values.buyCurrencyCode && !form.values.sellCurrencyCode}
                    label={t('reference_(drawdown)')}
                    name="reference"
                    id="reference"
                    testId="reference"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.reference}
                    error={
               form.touched.reference
               && Boolean(form.errors.reference)
             }
                    helperText={
               form.touched.reference
               && form.errors.reference
             }
                  />

                  {form.values.fundingMethod === TradeFundingMethod.WIRE_TRANSFER && firstPartyFlow
           && (
           <Box style={{ marginTop: '20px' }}>
             <div>
               <Alert
                 variant="info"
                 text={t('first_party_spot_info')}
               />
             </div>
           </Box>
           )}
                </div>
              </Box>
            )}

            <Actions
              form={form}
              setValidatedTrade={setValidatedTrade}
              setMfaVerified={setMfaVerified}
              setLiveRate={setLiveRate}
              mfaVerified={mfaVerified}
              handleValidateTrade={handleValidateTrade}
              liveRateStatus={liveRateStatus}
              pageLoading={pageLoading}
              rateLoading={rateLoading}
              valueDateLoading={valueDatesLoading}
              handleBookTrade={handleBookTrade}
              quoteLiveRate={quoteLiveRate}
              insufficientFunds={insufficientFunds()}
              validatedTrade={validatedTrade}
              submittingDraft={submittingDraft}
              handleClearAll={() => {
                setOpenClearAllConfirmation(true);
              }}
            />
          </Box>
          <DuplicatedTradeModal
            open={duplicateWarningModal}
            onClose={() => { setDuplicateWarningModal(false); }}
            potentialDuplicates={(validatedTrade as TradeSubmissionDto)?.potentialDuplicates || []}
            handleAcknowledgeDuplicate={handleAcknowledgeDuplicate}
          />
          <ConfirmationModal
            variant="negative"
            title={(
              <p style={{ lineHeight: '52px', fontSize: '16px', letterSpacing: 'unset' }}>
                <img src={Warning} style={{ width: '52px', height: '52px', verticalAlign: 'middle' }} alt="Warning" />
                <span style={{ verticalAlign: 'middle' }}>
                  {' '}
                  {t('are_you_sure')}
                </span>
              </p>
            )}
            open={openClearAllConfirmation}
            onExit={() => setOpenClearAllConfirmation(false)}
            onConfirm={() => {
              handleClearAll();
              setOpenClearAllConfirmation(false);
              sb.trigger(t('cleared_all_input'), 'info');
            }}
            confirmButtonText={t('clear')}
            exitButtonText={t('cancel')}
            content={(
              <p style={{ padding: '10px', fontSize: '13px', fontWeight: 400 }}>
                {t('you_will_be_clearing_all_input_from_this_form')}
                {' '}
                <wbr />
                {t('will_not_be_saved')}
              </p>
            )}
          />
          <PaymentContext.Provider value={{
            currencyCode: form?.values?.defaultBuyCurrencyAccount?.currencyCode,
            currency: form?.values?.defaultBuyCurrencyAccount?.currency,
            ...form,
          }}
          >
            <CreateBeneficiary
              open={beneficiaryDrawerOpen}
              header="Create New Beneficiary"
              handleDrawerClose={() => setBeneficiaryDrawerOpen(false)}
              firstPartyFlow={isEMoneyDisabled()}
            />
          </PaymentContext.Provider>
        </>
      )}
    </Box>
  );
};

export default InputSpotTrade;
