import React from 'react';

import { Box } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import { formatNumber, getCurrencySymbol } from '../../../../utils/currency.helpers';
import { TData } from '../Dropdown';

import { useStyles } from './RenderValue.styles';

interface IProps {
  selectedAccount: TData,
  hideBalance?: boolean,
  hideFlag?: boolean,
  fx?: boolean,
}
const RenderValue: React.FC<IProps> = ({
  selectedAccount, hideBalance, hideFlag, fx,
}: IProps) => {
  const styles = useStyles({ fx });
  const { friendlyName, balance, currencyCode } = selectedAccount;
  const currencySymbol = getCurrencySymbol(currencyCode!);
  const formattedBalance = formatNumber(+balance, 2);
  const currencySymbolAndFormattedBalance = `${currencySymbol}${formattedBalance}`;

  return (
    <Box className={styles.root}>
      {!hideFlag
        ? (
          <Box className={styles.imageHolder}>
            <Flag code={currencyCode} size="md" />
          </Box>
        ) : null}
      <Typography variant="subtitle1" className={styles.friendlyName}>{friendlyName}</Typography>
      <>
        <Box className={styles.verticalLine} />
        <Typography variant="subtitle1" className={styles.balance}>{!hideBalance ? currencySymbolAndFormattedBalance : '...'}</Typography>
      </>
    </Box>
  );
};

export default RenderValue;
