import React from 'react';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';

import Alert from '../../components/Alert';
import useSwitchAccount from '../../hooks/useSwitchAccount';

const liveRateChartPath = 'https://liverates.rmportal.alpha.co.uk/index.html';

const MarketInformation: React.FC = () => {
  const { homePageUrl } = useSwitchAccount();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl }];

  return (
    <APMainLayout
      height="204px"
      title={t('market_information')}
      breadCrumbs={breadcrumbs}
      sticky
      pageTabs={(
        <div style={{ display: 'inline-block' }}>
          <Alert
            variant="info"
            text={t('contact_for_tradable_rates')}
          />
        </div>
      )}
    >

      <Box style={{ textAlign: 'center', width: '100%', background: '#fafafa' }}>
        <iframe
          src={liveRateChartPath}
          frameBorder="0"
          width="1600"
          height="1600"
          scrolling="no"
          title={t('alpha_market_information')}
          style={{ margin: 'auto', maxWidth: '100%', minWidth: '600px' }}
          allowTransparency
        />
      </Box>
    </APMainLayout>
  );
};

export default MarketInformation;
