import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import { TUserCredentials } from '../models/user';

import useHandleLogin from './useHandleLogin';

const initialValue: TUserCredentials = {
  username: '',
  password: '',
};

const validation = (onboarding: boolean) => {
  let language = localStorage.getItem('i18nextLng');
  if (!language) {
    language = 'en';
  }

  const yupSchema = {
    username: yup.string().required(t('username_is_required')),
    password: yup.string().required(t('password_is_required')),
  };

  if (onboarding) {
    yupSchema.password = yup.string().min(8, t('password_must_be_at_least_8_characters')).required(t('password_is_required'));
  }

  return yup.object(yupSchema);
};

const useLogin = (onboarding = false, fromAlphaConnect = false) => {
  const snackbar = originalUseSnackbar();
  const { loading, handleLogin } = useHandleLogin();
  const [validationSchema, setValidationSchema] = useState(validation(onboarding));

  useEffect(() => {
    setValidationSchema(validation(onboarding));
  }, [onboarding]);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values: TUserCredentials) => {
      try {
        await handleLogin(
          values.username.trim(),
          values.password,
          fromAlphaConnect,
        );
      } catch (e) {
        let errorMessage: string = e.message;
        if (errorMessage.indexOf('validation') > -1) {
          errorMessage = t('incorrect_username_or_password');
        }
        snackbar.enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });
      }
    },
    validationSchema,
  });

  return {
    formik,
    loading,
  };
};

export default useLogin;
