import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(({ typography }) => ({
  alert: {
    margin: '20px 0px 20px 0px',
    padding: '22px 17px 23px 20px',
    color: '#CF752F',
    '& .text': {
      color: '#CF752F !important',
      fontWeight: 600,
    },
    '& .subText': {
      marginTop: '20px',
      color: '#CF752F !important',
      fontWeight: 400,
    },
  },
  tradeContainer: {
    backgroundColor: '#F4F4F4',
    padding: '22px 17px 23px 20px',
    borderRadius: '10px',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '5px',
  },
  textStyle: {
    fontSize: 13,
    fontWeight: 400,
    lineHeigt: '15px',
    fontFamily: typography.fontFamilySecondary,
  },
  subHeading: {
    fontFamily: typography.fontFamilySecondary,
    fontSize: '14px',
    fontWeight: typography.fontWeightSemiBold,
    paddingBottom: '30px',
  },
  buttonContainer: {
    padding: '46px 0px 24px 0px',
    marginTop: 'auto',
  },
  wireTransferGuide: {
    fontWeight: 300,
    fontSize: '12px',
    color: '#212529',
    lineHeight: '19.5px',
    padding: '8px',
    marginTop: '40px',
  },
  accountDetailTable: {
    background: 'transparent',
    marginTop: '12px',
    '& .MuiTableBody-root tr': {
      borderBottom: 'none',
    },
    '& .MuiTableBody-root tr td': {
      padding: '5px 0px !important',
    },
  },
  wireDetailsHeader: {
    marginTop: '15px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioSubtitle: {
    opacity: '50%',
    fontFamily: typography.fontFamilySecondary,
    fontSize: 12,
    padding: '0px 0px 24px 28px',
  },
  divider: {
    padding: '0px 0px 24px 0px',
  },
  transferDivider: {
    padding: '24px 0px 24px 0px',
  },
}), { name: 'ConfirmTrade' });

export default useStyles;
