import environmentVars from 'env.variables';
import useAuthorization from 'hooks/useAuthorization';
import { UserRole } from 'models/user';
import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import t from 'utils/translationHelper';

import { CreditFacilityDto } from '@alpha/credit-dtos';
import { Box } from '@alpha/ui-lib/ui/external';
import { ControlledSidebar, SidebarContext, TSidebarTypes } from '@alpha/ui-lib/ui/layout/PersistentSidebar';
import { faChartNetwork } from '@fortawesome/pro-light-svg-icons';
import {
  faAnalytics,
  faArrowUpRightFromSquare,
  faBuildingColumns,
  faBusinessTime,
  faChartLineUp,
  faCircleQuestion,
  faHandHoldingDollar,
  faHouseChimney,
  faLayerGroup,
  faReceipt,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import routes from '../../../routes.path';

interface SidebarProps {
  viewableTabs?: string[];
}

export const Sidebar: FC<SidebarProps> = ({ viewableTabs = [] }: SidebarProps) => {
  const CurrentControlledSidebar = ControlledSidebar;
  const CurrentSidebarContext = SidebarContext;

  const { toggle } = useContext(CurrentSidebarContext);

  const creditFacility = useSelector<TStore, CreditFacilityDto | undefined>(
    (state: TStore) => state.creditFacility.facility,
  );

  const homeRoute = {
    onClick: () => toggle(),
    url: `${routes.dashboard}`,
    label: t('home'),
    name: 'home',
    key: 'HOME',
    icon: faHouseChimney,
  };

  const transactionsRoute = {
    onClick: () => toggle(),
    url: `${routes.transactions.fxtrades}`,
    label: t('transactions'),
    name: 'transactions',
    key: 'TRANSACTIONS', // KEY for checking page configuration
    icon: faHandHoldingDollar,
  };

  const beneficiaryRoute = {
    onClick: () => toggle(),
    url: `${routes.beneficiaries}`,
    label: t('beneficiaries'),
    name: 'beneficiaries',
    key: 'BENEFICIARIES',
    icon: faUsers,
  };

  const accountsRoute = {
    onClick: () => toggle(),
    url: `${routes.currencyAccounts}`,
    label: t('accounts'),
    name: 'accounts',
    key: 'ACCOUNTS',
    icon: faBuildingColumns,
  };

  const statementsRoute = {
    onClick: () => toggle(),
    url: `${routes.noStatements}`,
    label: t('statements'),
    name: 'statements',
    key: 'STATEMENTS',
    icon: faReceipt,
  };

  const marketOrdersRoute = {
    onClick: () => toggle(),
    url: `${routes.marketOrders}`,
    label: t('market_orders'),
    name: 'marketOrders',
    key: 'MARKET_ORDERS',
    icon: faBusinessTime,
  };

  const marketInformationRoute = {
    onClick: () => toggle(),
    url: `${routes.marketInformation}`,
    label: t('market_information'),
    name: 'marketInformation',
    key: 'MARKET_INFORMATION',
    icon: faChartLineUp,
  };

  const hedgingProgramsRoute = {
    onClick: () => toggle(),
    url: `${routes.hedgingPrograms}`,
    label: t('hedging_programs'),
    name: 'hedgingPrograms',
    key: 'HEDGING_PROGRAMS',
    icon: faAnalytics,
  };

  const userGuidesRoute = {
    onClick: () => toggle(),
    url: `${routes.userGuides}`,
    label: t('user_guides'),
    name: 'userGuides',
    key: 'SUPPORT',
    icon: faCircleQuestion,
  };

  const AlphaConnectRoute = {
    onClick: () => {
      window.open(environmentVars.ALPHA_CONNECT_URL_HOME);
    },
    label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      Alpha Connect<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '6px' }} />
    </div>,
    name: 'alphaConnect',
    key: 'ALPHA_CONNECT',
    icon: faChartNetwork,
    bottom: true,
  };

  const creditFacilityRoute = {
    onClick: () => toggle(),
    url: `${routes.credit}`,
    label: t('credit_facility'),
    name: 'creditFacility',
    key: 'CREDIT_FACILITY',
    icon: faLayerGroup,
  };

  const defaultMenuItems = [transactionsRoute,
    beneficiaryRoute,
    accountsRoute,
    statementsRoute,
    marketOrdersRoute,
    creditFacilityRoute,
    marketInformationRoute,
    hedgingProgramsRoute,
    userGuidesRoute,
    AlphaConnectRoute];

  if (useAuthorization([[UserRole.ALPHA_CONNECT_LOGIN]]).authorized) {
    viewableTabs.push('ALPHA_CONNECT');
  }

  const [adaptedMenu, setAdaptedMenu] = useState(defaultMenuItems);

  useEffect(() => {
    let newAdaptedMenu = defaultMenuItems.filter((item) => viewableTabs.includes(item.key));
    newAdaptedMenu.sort((a, b) => a.name.localeCompare(b.name));
    newAdaptedMenu.unshift(homeRoute);

    if (!creditFacility?.facilityTerms?.length) {
      newAdaptedMenu = newAdaptedMenu.filter((menu) => menu.key !== 'CREDIT_FACILITY');
    }

    if (newAdaptedMenu !== adaptedMenu) {
      setAdaptedMenu(newAdaptedMenu);
    }
  }, [viewableTabs, creditFacility?.facilityTerms]);

  const sidebarProps = {
    type: 'static' as TSidebarTypes,
    showLogo: true,
  };

  return (
    <>
      <Box width="60px" height="100%" id="sideBar-Margin" />
      <div
        data-testid="portal-sidebar"
        style={{
          height: 'calc(100%)',
          position: 'fixed',
          zIndex: '1301',
        }}
      >
        <CurrentControlledSidebar
          hasMouseHandler={false}
          showLabel={false}
          showOpenButton={false}
          appName="Portal"
          items={adaptedMenu}
          locale={undefined}
          locales={undefined}
          zIndex={1201}
          {...sidebarProps}
        />
      </div>
    </>
  );
};

export default Sidebar;
