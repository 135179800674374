import React from 'react';
import clsx from 'clsx';
import t from 'utils/translationHelper';

import {
  Box,
  TextField,
} from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Autocomplete, FilterOptionsState } from '@alpha/ui-lib/ui/Select';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useStyles } from './AutocompleteDropDown.styles';

export interface IOption {
  code: string;
  paymentInfo?: string;
  disabled?: boolean;
  countryCode?: string;
  id?: string;
  name: string | undefined;
  alternativeName?: string;
}

interface IAutocompleteDropDown {
  type?: 'CODE' | 'NAME' | 'CODE-NAME';
  placeholder?: string;
  hideFlag?: boolean;
  testId?: string;
  options: any;
  name: string;
  setFieldValue: Function;
  value?: any;
  touched?: any;
  errors?: any;
  currentOption: IOption;
  textFieldValue: string;
  disabled?: boolean;
  setTextFieldValue: React.Dispatch<React.SetStateAction<string>>;
  handleKeyUp?: (textFieldValue: string) => void;
  setCurrentOption: React.Dispatch<React.SetStateAction<IOption>>;
  size?: 'small' | 'medium';
  filterOptions?: (options: any[], state: FilterOptionsState) => any[];
  dropdownHeight?: string,
  backgroundColor?: string,
  fontSize?: string,
  fontWeight?: number
  fontSizeOutput?: string;
}

const AutocompleteDropDown: React.FC<IAutocompleteDropDown> = (
  {
    type,
    options,
    placeholder,
    hideFlag,
    name,
    testId,
    setFieldValue,
    handleKeyUp,
    value,
    touched,
    errors,
    currentOption,
    disabled,
    setCurrentOption,
    textFieldValue,
    setTextFieldValue,
    size,
    filterOptions,
    dropdownHeight,
    backgroundColor,
    fontSize,
    fontSizeOutput,
    fontWeight,
  }: IAutocompleteDropDown,
) => {
  const classes = useStyles({
    dropdownHeight, backgroundColor, fontSize, fontWeight, fontSizeOutput,
  });

  const showInputValue = () => {
    const dropdownValue = options?.find((option: any) => typeReturn(option) === value);
    if (dropdownValue) {
      return {
        inputValue: typeReturn(dropdownValue),
      };
    }
  };

  const handleChange = (_name: string, valueCode: string, option: IOption): void => {
    setFieldValue(_name || valueCode, valueCode, option);
    setCurrentOption(option ?? { name: '', code: '' });
  };

  const handleDisplayIcon = (): JSX.Element => {
    if (currentOption && currentOption.code) {
      if (textFieldValue === typeReturn(currentOption)) {
        return (
          <Box className={clsx(classes.optionIcon, hideFlag && 'hideFlag')}>
            <Flag code={currentOption.code} size="md" />
          </Box>
        );
      }
    }
    return <></>;
  };

  const setDropdownWrapper = () => {
    if (currentOption && textFieldValue) {
      if (textFieldValue === typeReturn(currentOption)) {
        return classes.dropdownWrapperSelected;
      }
    }
  };

  const typeReturn = (prop: IOption) => {
    switch (type) {
      case 'CODE':
        return prop?.code;
      case 'NAME':
        return prop.name;
      case 'CODE-NAME':
        if (prop.code && prop.name) { return `${prop.code} (${prop.name})`; }
        return `${prop.code}`;
      default:
        return prop.name;
    }
  };

  return (
    <Box className={setDropdownWrapper()}>
      <Autocomplete
        disabled={disabled}
        noOptionsText={t('no_options')}
        data-testid={testId}
        popupIcon={<FontAwesomeIcon icon={faAngleDown as IconProp} />}
        onInputChange={(event, _value) => setTextFieldValue(_value)}
        className={clsx(classes.dropdown, hideFlag && classes.hideFlag)}
        size={size || 'small'}
        autoHighlight={false}
        getOptionDisabled={(option) => Boolean(option.disabled)}
        blurOnSelect={false}
        onChange={(_: unknown, option: any | null) => handleChange(name, option?.code, option)}
        id={name}
        closeIcon={false}
        value={currentOption}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={(placeholder || t('select'))}
            name={name}
            error={touched && Boolean(errors)}
            helperText={touched && errors}
            variant="filled"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
              form: {
                autoComplete: 'new-off',
              },
            }}
            onKeyUp={() => handleKeyUp && handleKeyUp(textFieldValue)}
          />
        )}
        renderOption={(option: IOption, { selected }) => {
          if (option.code === 'divider') {
            return (
              <div className={classes.divider} />
            );
          }
          return (
            <>
              <div className={clsx(classes.output, hideFlag && 'hideFlag')}>
                <Flag code={option.code} size="md" />
              </div>
              <div className={classes.labelWrapper}>
                <Typography data-testid={`currency option: ${option.code}`} className={classes.outputText}>{typeReturn(option)}</Typography>
                <div className={classes.extraInfoWrapper}>
                  {
                    hideFlag
                    && option.countryCode
                    && <Typography className={classes.paymentInfo}>{option.countryCode}</Typography>
                  }
                  {
                    hideFlag
                    && option.code
                    && <Typography className={classes.paymentInfo}>{option.code}</Typography>
                  }
                  {
                    option.paymentInfo
                    && <Typography data-dd-privacy="mask" className={classes.paymentInfo}>{option.paymentInfo}</Typography>
                  }
                  {
                    selected
                    && <FontAwesomeIcon className={classes.selectedCheck} icon={faCheckCircle as IconProp} />
                  }
                </div>
              </div>
            </>
          );
        }}
        getOptionLabel={(option) => (typeReturn(option) || '')}
        filterOptions={filterOptions}
        {...showInputValue()}
      />
      {handleDisplayIcon()}
    </Box>
  );
};

export default AutocompleteDropDown;
