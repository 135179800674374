import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  imageHolder: {
    display: 'flex',
    overflow: 'hidden',
    marginRight: '8px',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
  },
  friendlyName: (props: any) => ({
    fontSize: props.fx ? '16px' : '14px',
    color: props.fx ? '#34343473' : 'black',
    fontWeight: props.fx ? 400 : 0,
  }),
  verticalLine: {
    height: '19px',
    position: 'relative',
    margin: '0 8px',
    borderRight: '1px solid #70707026',
  },
  balance: (props: any) => ({
    color: '#34343473',
    fontSize: props.fx ? '16px' : '14px',
    fontWeight: props.fx ? 400 : 0,
    whiteSpace: props.fx ? 'nowrap' : 'unset',
    overflow: props.fx ? 'hidden' : 'unset',
    textOverflow: props.fx ? 'ellipsis' : 'unset',
  }),
}), { name: 'InterAccountTransferDropdownItem' });
export default useStyles;
