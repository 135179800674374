import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

import SearchService from '../services/Search/SearchService';

import useAlphaSnackbar from './useAlphaSnackbar';

export type TSearchParams = {
  baseUrl: string,
  queryParams: {
    searchtext?: string,
    take: number,
    skip: number,
    sortby?: string,
    sortorder?: 'asc' | 'desc',
    includeOptions?: boolean,
    mtmcurrency?: string,
  } & Record<string, string | number>,
  body?: Record<string, unknown>,
}

export type TSearchBaseResponse = {
  total: number;
  totalForwards?: number;
  totalOptions?: number;
  records: unknown[];
}

export type TSearchItems<T> = {
  items: T,
  hasPrevious: boolean,
  hasNext: boolean,
  total: number,
  totalForwards?: number,
  totalOptions?: number
}
export type TErrorResponseWrapper = {
  response: {
    data: {
      error: string,
      errors: errorType[] | string[]
    }
  }
}

export type errorType = {
  key: string,
  errors: string[]
}

function createErrorMessage(e: TErrorResponseWrapper): string {
  try {
    const errorData = e.response.data;
    const errorArray = errorData.errors as errorType[];
    let errorMessage = '';
    if (errorArray[0].errors) {
      errorArray.forEach((element) => {
        element.errors.forEach((error) => {
          errorMessage = errorMessage.concat(`${errorData?.error} : ${error}\n`);
        });
      });
    } else {
      errorArray.forEach((error) => {
        errorMessage = errorMessage.concat(`${errorData?.error} : ${error}\n`);
      });
    }
    return errorMessage;
  } catch {
    return 'Something went wrong when retrieving your data';
  }
}

export type TUseSearch = ReturnType<typeof useSearch>;

const useSearch = <T extends TSearchBaseResponse>() => {
  const [searchText, setSearchText] = useState<string>('');
  const [items, setItems] = useState<TSearchItems<T>>();
  const [skip, setSkip] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialised, setInitialised] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState<string>('uploadedByDate');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const sb = useAlphaSnackbar();

  async function fetchItemsFromUrl(searchParams: TSearchParams):
    Promise<T | null> {
    try {
      setLoading(true);
      return await SearchService.GetTableData(searchParams);
    } catch (e) {
      sb.trigger(createErrorMessage(e) || e?.message);
    } finally {
      setLoading(false);
    }
    return null;
  }

  const createSearchItemsObj = (responseItems: T, searchParams: TSearchParams): TSearchItems<T> => {
    const hasPrevious = Boolean(searchParams.queryParams.skip > 0);
    const hasNext = Boolean(
      responseItems.total - (searchParams.queryParams.skip + searchParams.queryParams.take) > 0,
    );
    return {
      items: responseItems,
      hasPrevious,
      hasNext,
      total: responseItems.total,
      totalForwards: responseItems.totalForwards,
      totalOptions: responseItems.totalOptions,
    };
  };

  const handleUpdateTableItems = async (searchParams: TSearchParams): Promise<void> => {
    const responseItems: T | null = await fetchItemsFromUrl(searchParams);
    if (responseItems) {
      const searchItems = createSearchItemsObj(responseItems, searchParams);
      setItems(searchItems);
      setInitialised(true);
    }
  };

  const handleNewSearch = useCallback(debounce(
    async (searchParams: TSearchParams): Promise<void> => {
      const newSearchParams = { ...searchParams };
      newSearchParams.queryParams.skip = newSearchParams.queryParams.skip || 0;
      newSearchParams.queryParams.sortby = newSearchParams.queryParams.sortby || sortBy;
      newSearchParams.queryParams.sortorder = newSearchParams.queryParams.sortorder || 'desc';
      newSearchParams.queryParams.searchtext = newSearchParams.queryParams.searchtext?.trim();

      if (newSearchParams.queryParams.searchtext === '') {
        delete newSearchParams.queryParams.searchtext;
      }
      setSkip(newSearchParams.queryParams.skip);
      await handleUpdateTableItems(newSearchParams);
    }, 300,
  ), []);

  const handleInitialSearch = useCallback(debounce(
    async (searchParams: TSearchParams): Promise<void> => {
      const newSearchParams = { ...searchParams };
      newSearchParams.queryParams.skip = 0;
      newSearchParams.queryParams.searchtext = undefined;
      setSearchText('');
      setSkip(0);
      setSortBy(searchParams.queryParams.sortby || '');
      setSortOrder(searchParams.queryParams.sortorder || 'desc');
      await handleUpdateTableItems(newSearchParams);
    }, 300,
  ), []);

  const handlePaginationRequest = async (searchParams: TSearchParams, newSkip: number) => {
    setSkip(newSkip < 0 ? 0 : newSkip);

    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.skip = newSkip;

    if (searchText) { newSearchParams.queryParams.searchtext = searchText; }
    await handleUpdateTableItems(newSearchParams);
  };

  const handleNextPage = async (searchParams: TSearchParams,
    skipAmount = 10): Promise<void> => {
    const newSkip = skip + skipAmount;

    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.sortby = sortBy;
    newSearchParams.queryParams.sortorder = sortOrder;
    await handlePaginationRequest(newSearchParams, newSkip);
  };

  const handlePreviousPage = async (searchParams: TSearchParams,
    skipAmount = 10): Promise<void> => {
    const newSkip = skip - skipAmount;

    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.sortby = sortBy;
    newSearchParams.queryParams.sortorder = sortOrder;
    await handlePaginationRequest(newSearchParams, newSkip);
  };

  const handleTableSortClick = (searchParams: TSearchParams, column: string) => {
    let newOrder: 'asc' | 'desc';

    if (column === sortBy) {
      newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      newOrder = 'desc';
    }

    setSortOrder(newOrder);
    setSortBy(column);
    setSkip(0);
    const newSearchParams = { ...searchParams };
    newSearchParams.queryParams.sortorder = newOrder;
    newSearchParams.queryParams.sortby = column;
    newSearchParams.queryParams.skip = 0;
    if (searchText) newSearchParams.queryParams.searchtext = searchText.trim();
    handleNewSearch(newSearchParams);
  };

  return {
    items,
    skip,
    loading,
    searchText,
    handleNewSearch,
    setItems,
    setSearchText,
    handleInitialSearch,
    handlePreviousPage,
    handleNextPage,
    handleTableSortClick,
    sortBy,
    sortOrder,
    initialised,
  };
};

export default useSearch;
