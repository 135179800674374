import { BeneficiaryDto } from '@alpha/bene-dtos';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { TradeFundingMethod } from '@alpha/fx-dtos';

export type TForwardsFXRequestForm = {
  fundingMethod: TradeFundingMethod;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  fixedCurrencyCode: string;
  fixedAmount: number;
  currencyPair: string,
  instructionType: string,
  sellAmount: number;
  buyAmount: number;
  indicativeRate: number;
  valueDate: string;
  tradeId: string;
  defaultSellCurrencyAccount?: CurrencyAccountDto;
  defaultBuyCurrencyAccount?: CurrencyAccountDto;
  quoteRate?: number;
  beneficiary?: BeneficiaryDto;
  forwardReason?: string;
  fundingCurrency?: string;
  forwardType?: string;
  initialMarginPercentage?: number;
  initialMarginDueDate?: string;
  initialMarginSide?: string;
  initialMarginAmount?: string;
  directInvestment?: boolean;
  settledPhysically?: boolean;
  selectedBuyCurrencyAccount?: CurrencyAccountDto;
  selectedSellCurrencyAccount?: CurrencyAccountDto;
};

export const initialValues: TForwardsFXRequestForm = {
  fundingMethod: TradeFundingMethod.WIRE_TRANSFER,
  sellCurrencyCode: '',
  buyCurrencyCode: '',
  fixedCurrencyCode: '',
  fixedAmount: 0.00,
  sellAmount: 0.00,
  buyAmount: 0.00,
  indicativeRate: 0,
  valueDate: '',
  forwardReason: '',
  tradeId: '',
  currencyPair: '',
  instructionType: '',
  forwardType: 'Fixed',
  initialMarginPercentage: 0,
  initialMarginSide: 'Sell',
  initialMarginDueDate: '',
  initialMarginAmount: '',
  directInvestment: undefined,
  settledPhysically: undefined,
  selectedBuyCurrencyAccount: undefined,
  selectedSellCurrencyAccount: undefined,
  defaultSellCurrencyAccount: undefined,
  defaultBuyCurrencyAccount: undefined,
};

export default initialValues;
