import { makeStyles } from '@alpha/ui-lib/theme';

import { rateType } from './Rate';

export const useStyles = makeStyles(
  ({ typography }) => ({
    root: (props: any) => ({
      minWidth: '174px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: props.loading ? 'center' : 'space-between',
      padding: '3px 8px 10px 8px',
      width: 'auto',
      height: '44px',
      backgroundColor: props.type === rateType.LIVE && !props.loading ? '#1E8777' : '#ECF3F9',
      borderRadius: '10px',
      '& .MuiTypography-root': {
        alignSelf: 'center',
      },
      marginLeft: props.mfaVerified ? '55px' : '0px',
    }),
    rateFont: {
      alignSelf: 'center',
      display: 'flex',
      height: 13,
      color: '#3E8CC1',
      fontWeight: 400,
      fontSize: 13,
    },
    coloredBackground: {
      padding: '4px 10px',
      textTransform: 'uppercase',
      letterSpacing: 1.5,
      borderRadius: 5,
    },
    live: {
      color: '#FFFFFF',
    },
    indicative: {
      color: '#3E8CC1',
      fontWeight: 400,
      fontSize: '13px',
    },
    loading: {
      color: '#3E8CC1',
      padding: '4px 18px 3px 8px',
      width: '174px',
    },
    expired: {
      color: 'black',
      backgroundColor: '#ECF3F9',
    },
    liveRate: {
      color: '#FFFFFF',
      backgroundColor: '1E8777',
    },
    rate: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '6px',
    },
    inverseRateWrapper: {
      display: 'flex',
      alignItems: 'center',
      borderLeft: '1px solid ',
      paddingLeft: 8,
      marginTop: '8px',
    },
    inverseRate: {
      fontweight: 300,
      fontSize: '13px',
      marginBottom: '2px',
    },
    icon: {
      alignSelf: 'center',
      marginLeft: 5,
      marginTop: -5,
      height: '6px',
      width: '6px',
    },
    tooltip: {
      width: 160,
    },
    amount: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiTypography-root': {
        marginRight: '8px',
        lineHeight: '26px',
      },
      '& > svg': {
        position: 'relative',
      },
    },
    green: {
      color: '#1E8777',
      top: '4px',
    },
    red: {
      color: '#B95656',
      top: '-2px',
    },
    bold: {
      fontWeight: 'bold',
      marginLeft: 5,
    },
    light: {
      fontWeight: 300,
    },
    exampleRate: {
      fontWeight: 'bold',
      color: '#707070',
    },
    verticalLine: {
      height: '25px',
      borderRight: '1px solid #E2E2E2',
    },
  }), { name: 'FXIndicativeRate' },
);

export default useStyles;
