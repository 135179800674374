import React from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import t from 'utils/translationHelper';

import {
  QuoteResponse,
  TradeDto, TradeFundingMethod, TradeSubmissionDto,
} from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box, LinearProgress } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRotateForward, faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from '../../../../../Alert';
import { TFxBookingState } from '../..';
import { TForwardsFXRequestForm } from '../../formData';
import { useStyles } from '../index.styles';

interface IProps {
  form: FormikProps<TForwardsFXRequestForm>;
  setValidatedTrade: React.Dispatch<React.SetStateAction<
    TradeSubmissionDto | TradeDto | undefined>>;
  setMfaVerified: React.Dispatch<React.SetStateAction<boolean>>;
  setLiveRate: React.Dispatch<React.SetStateAction<QuoteResponse | undefined>>;
  mfaVerified: boolean;
  handleValidateTrade: () => void;
  liveRateStatus: TFxBookingState;
  pageLoading: boolean;
  rateLoading: boolean;
  valueDateLoading: boolean;
  insufficientFunds: boolean;
  handleBookTrade: () => void;
  quoteLiveRate: () => void;
  validatedTrade: TradeSubmissionDto | TradeDto | undefined;
  handleClearAll: () => void;
  timerForRate?: number;
}

const Actions: React.FC<IProps> = ({
  form,
  setValidatedTrade,
  setMfaVerified,
  mfaVerified,
  handleValidateTrade,
  liveRateStatus,
  setLiveRate,
  insufficientFunds,
  rateLoading,
  valueDateLoading,
  pageLoading,
  handleBookTrade,
  quoteLiveRate,
  validatedTrade,
  handleClearAll,
  timerForRate,
}: IProps) => {
  const styles = useStyles();

  const buttonClassname = clsx({
    [styles.buttonSuccess]: liveRateStatus === 'successBookTrade',
  });

  const styledText = (
    <span>
      <span style={{ fontWeight: 600 }}>{t('please_note^')}</span>
      {' '}
      <span style={{ fontWeight: 400 }}>{t('if_you_clicked_book_fx')}</span>
    </span>
  );

  return (
    <>
      {!(validatedTrade && mfaVerified) ? (
        <Box
          style={{
            width: '100%', padding: '10px', position: 'sticky', bottom: 0, background: '#ffffff',
          }}
          display="flex"
          gridGap={10}
        >
          <ActionButton
            style={{
              width: '20%',
              color: form.dirty ? '#D55E5E' : '#D55E5E33',
              backgroundColor: '#d55e5e1a',
            }}
            disabled={!form.dirty}
            onClick={handleClearAll}
          >
            {t('clear_all')}
          </ActionButton>
          <ActionButton
            onClick={handleValidateTrade}
            style={i18n.language === 'de' ? { width: '94%' } : { width: '100%' }}
            disabled={pageLoading || rateLoading || valueDateLoading || !form.isValid || form.values.indicativeRate === 0
              || (insufficientFunds
                && form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE)}
          >
            <FontAwesomeIcon icon={faLock as IconProp} />
            {' '}
            {t('request_live_rate')}
          </ActionButton>
        </Box>
      ) : (
        <>
          <Box style={{ padding: '4px 8px', marginTop: 'auto' }}>
            <Alert variant="warning" className={styles.warningAlert} text={styledText} />
          </Box>
          <Box style={{ padding: '4px 8px', marginTop: '10px' }} gridGap={10} display="flex">
            <ActionButton
              style={{ background: '#F7F7F7', color: '#212529' }}
              onClick={() => {
                setValidatedTrade(undefined);
                setMfaVerified(false);
                setLiveRate(undefined);
              }}
              disabled={liveRateStatus === 'initiatedBookTrade'}
            >
              {t('cancel')}
            </ActionButton>
            {(liveRateStatus === 'success'
              || liveRateStatus === 'initiated'
                || liveRateStatus === 'initiatedBookTrade') ? (
                  <>
                    <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
                      <ActionButton
                        style={{ flexGrow: 1 }}
                        onClick={handleBookTrade}
                        loading={liveRateStatus === 'initiatedBookTrade'}
                        disabled={liveRateStatus !== 'success'}
                        className={buttonClassname}
                        color="primary"
                      >
                        <FontAwesomeIcon icon={faLock as IconProp} />
                        {' '}
                        {t('book_fx_trade')}
                      </ActionButton>
                      <LinearProgress
                        className={styles.buttonProgress}
                        variant="determinate"
                        value={timerForRate}
                        onClick={handleBookTrade}
                      />
                    </div>
                  </>
              ) : (
                <ActionButton
                  style={{ flexGrow: 1 }}
                  onClick={quoteLiveRate}
                  loading={liveRateStatus === 'loading'}
                  disabled={liveRateStatus === 'loading'}
                >
                  <FontAwesomeIcon icon={faArrowRotateForward as IconProp} />
                  {' '}
                  {t('refresh_rate')}
                </ActionButton>
              )}
          </Box>
        </>
      )}
    </>
  );
};

export default Actions;
