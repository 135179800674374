import { AxiosResponse } from 'axios';

import { FeatureFlag } from '@alpha/feature-flags';

import instance from '../Axios/instance';

export enum FeatureFlagName {
  LONGER_TOKEN_TIME = 'longer-token-time',
  IAT_CROSS_CURRENCY = 'iat-cross-currency',
  IAT_COMMUNICATION_SETTINGS = 'iat-communication-settings',
  COP = 'cop',
  FIXED_FORWARD = 'fixed-forward'
}

export class ConfigService {
  public static async getFeatureFlags(
  ): Promise<FeatureFlag[]> {
    const response: AxiosResponse<FeatureFlag[]> = await instance.get('/feature-flags');
    return response.data;
  }
}
export default ConfigService;
