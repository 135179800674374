import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import moment from 'moment';
import CurrencyAccountsService from 'services/CurrencyAccounts/currencyAccounts.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import { getCurrencySymbol } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { Currency } from '@alpha/bene-dtos/lib/responses/Currency';
import { AllowedCurrenciesDto, IndicativeRateResponse } from '@alpha/fx-dtos';

import { IOption } from '../../../../AutocompleteDropDown/AutocompleteDropDown';
import { TSpotFXRequestForm } from '../formData';

// eslint-disable-next-line max-lines-per-function
export const useInputSpotTrade = (
  form: FormikProps<TSpotFXRequestForm>,
) => {
  const emptyOption = { name: '', code: '' };
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const [
    allowedSellCurrenciesOption,
    setAllowedSellCurrenciesOption,
  ] = useState<IOption[]>([]);

  const [
    allowedBuyCurrenciesOption,
    setAllowedBuyCurrenciesOption,
  ] = useState<IOption[]>([]);

  const [
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
  ] = useState<IOption>(emptyOption);

  const [
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
  ] = useState<IOption>(emptyOption);

  const [fixedSide, setFixedSide] = useState<'sell' | 'buy'>();

  const [allowedBuyCurrencies, setAllowBuyCurrencies] = useState<Currency[]>([]);
  const [allowedSellCurrencies, setAllowSellCurrencies] = useState<Currency[]>([]);
  const [sellCurrencyTextFieldValue, setSellCurrencyTextFieldValue] = useState<string>('');
  const [buyCurrencyTextFieldValue, setBuyCurrencyTextFieldValue] = useState<string>('');
  const [indicativeRate, setIndicativeRate] = useState<IndicativeRateResponse>();
  const [availableSpotDates, setAvailableSpotDates] = useState<string[]>([]);

  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [rateLoading, setRateLoading] = useState<boolean>(false);
  const [valueDatesLoading, setValueDatesLoading] = useState<boolean>(false);

  const [buyStartAdornment, setBuyStartAdornment] = useState<string>('');
  const [sellStartAdornment, setSellStartAdornment] = useState<string>('');

  const getAvailableCurriencies = async (): Promise<void> => {
    setPageLoading(true);
    try {
      const allowCurrencies: AllowedCurrenciesDto = await FXTradeService.getAllowedFXCurrencies();
      setAllowBuyCurrencies(allowCurrencies.buyAllowed);
      setAllowedBuyCurrenciesOption(allowCurrencies.buyAllowed);
      setAllowSellCurrencies(allowCurrencies.sellAllowed);
      setAllowedSellCurrenciesOption(allowCurrencies.sellAllowed);

      setPageLoading(false);
    } catch (error) {
      sb.trigger(error?.message || 'Failed to load available currencies.', 'error');
      logError({ action: 'Error loading available currencies', error });
    }
  };

  const updateCounterSideAmount = async (
    amountFixedSide: 'buy' | 'sell',
    amount: number,
    rate: number,
    buyCcy: string,
    sellCcy: string,
  ): Promise<void> => {
    if (!(buyCcy && sellCcy)) {
      return;
    }

    const opposingFieldAmount = FXTradeService.calculateTradeAmount(
      amountFixedSide,
      amount,
      rate || 0,
    );

    if (amountFixedSide === 'buy') {
      (async () => {
        await form.setFieldValue('sellAmount', opposingFieldAmount.toFixed(2), true);
        await form.setFieldTouched('sellAmount', true, false);
        form.validateField('sellAmount');
      })();
    } else {
      (async () => {
        await form.setFieldValue('buyAmount', opposingFieldAmount.toFixed(2), true);
        await form.setFieldTouched('buyAmount', true, false);
        form.validateField('buyAmount');
        await form.setFieldTouched('sellAmount', true, true);
      })();
    }
  };

  const getIndicativeRate = async (
    sellCurrencyCode: string,
    buyCurrencyCode: string,
  ): Promise<void> => {
    setRateLoading(true);
    try {
      const indicativeRateResponse = await FXTradeService.getSpotTradeIndicativeRate(
        sellCurrencyCode,
        buyCurrencyCode,
      );
      setIndicativeRate(indicativeRateResponse);
      form.setFieldValue('indicativeRate', indicativeRateResponse.rate);
      setRateLoading(false);
    } catch (e) {
      setIndicativeRate({ rate: 0.0, valueDate: new Date() });
      form.setFieldValue('indicativeRate', 0);
      setRateLoading(false);
      const errorMessage = (e.response?.data?.errors?.length
        && (t(e.response?.data?.errors[0]))) || t(e.response?.data?.error) || e.message || 'Unable to load indicative rate';
      sb.trigger(errorMessage, 'error');
      logError({ action: 'Error getting indicative rate', error: e });
    }
  };

  const getAvailableSpotDates = async (
    sellCurrencyCode: string,
    buyCurrencyCode: string,
  ): Promise<void> => {
    setValueDatesLoading(true);
    try {
      const availableSpotDatesResponse = await FXTradeService.getAvailableSpotDates(
        sellCurrencyCode,
        buyCurrencyCode,
      );
      setAvailableSpotDates([...availableSpotDatesResponse.availableDates]);
      setValueDatesLoading(false);
    } catch (e: any) {
      setAvailableSpotDates([]);
      setValueDatesLoading(false);
    }
  };

  const getDefaultCurrencyAccount = async (
    currencyCode: string,
  ) => {
    const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
      currencyCode,
    );

    const defaultCA = currencyAccounts.currencyAccounts?.find(
      (ca) => ca.default,
    );
    return defaultCA;
  };

  const insufficientFunds = (): boolean => {
    if (form.values?.defaultSellCurrencyAccount) {
      return +form.values.sellAmount.toString().replace(/,/g, '') > form.values?.defaultSellCurrencyAccount.clearedBalance;
    }
    return false;
  };

  const handleClearAll = () => {
    form.resetForm();
    setSelectedBuyCurrencyOption(emptyOption);
    setSelectedSellCurrencyOption(emptyOption);
  };

  useEffect(() => {
    getAvailableCurriencies();
    if (form.values.draftInitiated) {
      setFixedSide(form.values.fixedCurrencyCode === form.values.buyCurrencyCode ? 'buy' : 'sell');
    }
  }, []);

  useEffect(() => {
    const preservedSellCurrencyOption = allowedSellCurrenciesOption.find(
      (i) => i.code === form.values.sellCurrencyCode,
    );
    if (preservedSellCurrencyOption) { setSelectedSellCurrencyOption(preservedSellCurrencyOption); }
  }, [allowedSellCurrenciesOption]);

  useEffect(() => {
    const preservedBuyCurrencyOption = allowedBuyCurrenciesOption.find(
      (i) => i.code === form.values.buyCurrencyCode,
    );
    if (preservedBuyCurrencyOption) { setSelectedBuyCurrencyOption(preservedBuyCurrencyOption); }
  }, [allowedBuyCurrenciesOption]);

  useEffect(() => {
    if (selectedSellCurrencyOption
      && selectedBuyCurrencyOption
      && selectedSellCurrencyOption.code !== ''
      && selectedBuyCurrencyOption.code !== ''
      && selectedSellCurrencyOption.code !== selectedBuyCurrencyOption.code) {
      getIndicativeRate(selectedSellCurrencyOption.code, selectedBuyCurrencyOption.code);
      getAvailableSpotDates(selectedSellCurrencyOption.code, selectedBuyCurrencyOption.code);
    }
  }, [selectedSellCurrencyOption, selectedBuyCurrencyOption]);

  useEffect(() => {
    if (indicativeRate?.valueDate && availableSpotDates.length) {
      const earliestDate = moment(availableSpotDates[0]).isSameOrBefore(indicativeRate.valueDate)
        ? indicativeRate?.valueDate
        : availableSpotDates[0];

      form.setFieldValue('valueDate', earliestDate);
    }
  }, [indicativeRate?.valueDate, availableSpotDates]);

  useEffect(() => {
    setSellStartAdornment(getCurrencySymbol(form.values.sellCurrencyCode) || '');
    if (form.values.draftInitiated) {
      form.setFieldValue('sellAmount', form.values.sellAmount, false);
      form.setFieldValue('buyAmount', form.values.buyAmount, false);
      const buySideFixed = form.values.fixedCurrencyCode === form.values.buyCurrencyCode;
      form.setFieldTouched('sellAmount', !buySideFixed);
      form.setFieldTouched('buyAmount', buySideFixed);
    } else if (fixedSide !== 'sell') {
      // Clear sell amounts
      form.setFieldValue('sellAmount', 0, false);
      form.setFieldTouched('sellAmount', false);
    }

    if (fixedSide === 'sell') {
      form.setFieldValue('fixedCurrencyCode', form.values.sellCurrencyCode);
    }
    (async () => {
      if (form.values.sellCurrencyCode) {
        const defaultSellCurrencyAccount = await getDefaultCurrencyAccount(
          form.values.sellCurrencyCode,
        );
        form.setFieldValue('defaultSellCurrencyAccount', defaultSellCurrencyAccount);
      } else {
        form.setFieldValue('defaultSellCurrencyAccount', undefined);
      }
    })();
  }, [form.values.sellCurrencyCode]);

  useEffect(() => {
    setBuyStartAdornment(getCurrencySymbol(form.values.buyCurrencyCode) || '');
    if (form.values.draftInitiated) {
      form.setFieldValue('sellAmount', form.values.sellAmount, false);
      form.setFieldValue('buyAmount', form.values.buyAmount, false);
      const buySideFixed = form.values.fixedCurrencyCode === form.values.buyCurrencyCode;
      form.setFieldTouched('sellAmount', !buySideFixed);
      form.setFieldTouched('buyAmount', buySideFixed);
    } else if (fixedSide !== 'buy') {
      // Clear buy amount
      form.setFieldValue('buyAmount', 0, false);
      form.setFieldTouched('buyAmount', false);
    }
    if (fixedSide === 'buy') {
      form.setFieldValue('fixedCurrencyCode', form.values.buyCurrencyCode);
    }
    (async () => {
      if (form.values.buyCurrencyCode) {
        const defaultBuyCurrencyAccount = await getDefaultCurrencyAccount(
          form.values.buyCurrencyCode,
        );
        form.setFieldValue('defaultBuyCurrencyAccount', defaultBuyCurrencyAccount);
      } else {
        form.setFieldValue('defaultBuyCurrencyAccount', undefined);
      }
    })();
  }, [form.values.buyCurrencyCode]);

  const swapCurrencies = async () => {
    const newSellCcyCode = selectedBuyCurrencyOption.code;
    const newBuyCcyCode = selectedSellCurrencyOption.code;

    const newSellCcy = allowedSellCurrenciesOption.find((item) => (item.code === newSellCcyCode));
    const newBuyCcy = allowedBuyCurrenciesOption.find((item) => (item.code === newBuyCcyCode));

    // If selected, a buy currency can be swapped only if in the sell-allowed list , and vice versa
    const canSwap = (!newSellCcyCode || newSellCcy) && (!newBuyCcyCode || newBuyCcy);

    if (!canSwap) {
      sb.trigger('Note: could not swap these two currencies.', 'info');
      return;
    }

    setSelectedBuyCurrencyOption(newBuyCcy ?? { name: '', code: '' });
    setSelectedSellCurrencyOption(newSellCcy ?? { name: '', code: '' });

    form.setFieldValue('buyCurrencyCode', newBuyCcyCode, true);
    form.setFieldValue('sellCurrencyCode', newSellCcyCode, true);

    form.setFieldValue('buyAmount', form.values.sellAmount, true);
    form.setFieldValue('sellAmount', form.values.buyAmount, true);

    if (fixedSide) setFixedSide(fixedSide === 'buy' ? 'sell' : 'buy');
  };

  return {
    allowedSellCurrenciesOption,
    allowedBuyCurrenciesOption,
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
    sellCurrencyTextFieldValue,
    setSellCurrencyTextFieldValue,
    buyCurrencyTextFieldValue,
    setBuyCurrencyTextFieldValue,
    fixedSide,
    setFixedSide,
    indicativeRate,
    pageLoading,
    setPageLoading,
    rateLoading,
    updateCounterSideAmount,
    buyStartAdornment,
    setBuyStartAdornment,
    sellStartAdornment,
    setSellStartAdornment,
    insufficientFunds,
    availableSpotDates,
    valueDatesLoading,
    swapCurrencies,
    handleClearAll,
  };
};

export default useInputSpotTrade;
