import { useState } from 'react';
import t from 'utils/translationHelper';

import { MfaMethod } from '@alpha/profile-dtos';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import AuthService from '../services/Auth/auth.service';

import useAuth from './useAuth';
import useLog from './useLog';

const useHandleLogin = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { logError } = useLog();

  const snackbar = originalUseSnackbar();

  const handleLogin = async (
    username: string,
    password: string,
    fromAlphaConnect: boolean,
  ) => {
    try {
      setLoading(true);
      const response = await AuthService.signIn(username, password);
      if (!response.challengeName) {
        //  If MFA is disabled
        await auth.checkAuth();
      } else {
        await auth.challengeRedirect(
          response,
          snackbar.enqueueSnackbar,
          MfaMethod.METHOD_AUTHY,
          fromAlphaConnect,
        );
      }
    } catch (e: any) {
      const validMessages = [
        'Incorrect username or password.',
        'Password attempts exceeded',
        'User is disabled.',
      ];
      const errorMessage = validMessages.includes(e.message)
        ? e.message
        : 'There was an error logging you in';
      snackbar.enqueueSnackbar(t(errorMessage), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
      logError({ action: 'Error logging in', error: e.message });
    } finally {
      setLoading(false);
    }
  };

  return {
    auth,
    loading,
    setLoading,
    handleLogin,
  };
};

export default useHandleLogin;
