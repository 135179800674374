import React, {
  useCallback, useEffect, useState,
} from 'react';
import clsx from 'clsx';
import Dropdown from 'components/InterAccountTransfer/Dropdown/Dropdown';
import DrawerBackdropLoader from 'components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { FormikProps } from 'formik';
import i18n from 'i18n/config';
import { debounce } from 'lodash';
import { Moment } from 'moment';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import StatementsService from 'services/Statements/statements.service';
import { formatCurrency } from 'utils/currency.helpers';
import { filterByCurrencyAccountToData } from 'utils/mapDropdown';
import t from 'utils/translationHelper';

import { UserAcknowledgmentRequest, UserAcknowledgmentType } from '@alpha/auth-dtos';
import {
  ExecuteTradeResultDto,
  TradeDto,
  TradeRequest,
  TradeSubmissionDto,
  TradeType,
} from '@alpha/fx-dtos';
import { IconButton } from '@alpha/ui-lib/ui/button';
import { Checkbox } from '@alpha/ui-lib/ui/Checkbox/Checkbox';
import { APDatePicker } from '@alpha/ui-lib/ui/DatePicker';
import {
  Box, Divider, Input, InputLabel, MenuItem, Select,
  Typography,
} from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { ConfirmationModal } from '@alpha/ui-lib/ui/Modal/ConfirmationModal';
import { RadioButtonGroup } from '@alpha/ui-lib/ui/RadioButton';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import AuthService from '../../../../../services/Auth/auth.service';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import { TStore } from '../../../../../store';
import { initiateTrade } from '../../../../../store/authy/actions';
import { TAuthyState } from '../../../../../store/authy/reducer';
import AutocompleteDropDown from '../../../../AutocompleteDropDown/AutocompleteDropDown';
import BackdropLoader from '../../../Loaders/BackdropLoader/BackdropLoader';
import Rate from '../components/Rate/Rate';
import { TForwardsFXRequestForm } from '../formData';
import { TForwardsDrawState } from '..';

import Actions from './Actions';
import DuplicatedTradeModal from './DuplicatedTradeModal';
import FundingMethod from './FundingMethod';
import { useStyles } from './index.styles';
import { useInputForwardTrade } from './useInputForwardTrade';
import useQuoteTradeRate from './useQuoteTradeRate';

interface IProps {
  form: FormikProps<TForwardsFXRequestForm>;
  handleBlur?: (event: any) => void;
  setDrawerState: React.Dispatch<React.SetStateAction<TForwardsDrawState>>;
  setBookedTrade: React.Dispatch<React.SetStateAction<ExecuteTradeResultDto | undefined>>;
  padAllowed?: boolean;
  validatedTrade?: TradeSubmissionDto | TradeDto;
  setValidatedTrade: React.Dispatch<React.SetStateAction<
    TradeSubmissionDto | TradeDto | undefined>>;
  handleClose: () => void;
}

// eslint-disable-next-line max-lines-per-function
const InputForwardTrade: React.FC<IProps> = ({
  form,
  handleBlur,
  setDrawerState,
  setBookedTrade,
  padAllowed = false,
  validatedTrade,
  setValidatedTrade,
  handleClose,
}) => {
  const styles = useStyles();
  const sb = useAlphaSnackbar();
  const dispatch = useDispatch();
  const { logError, logEvent } = useLog();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const [activeIcon, setActiveIcon] = useState(false);

  const handleChangeActive = () => {
    setActiveIcon((previousIcon) => !previousIcon);
  };

  const [directInvestment, setDirectInvestment] = useState<boolean>(false);
  const [settledPhysically, setSettledPhysically] = useState<boolean>(false);

  const [mfaVerified, setMfaVerified] = useState<boolean>(false);
  const [openClearAllConfirmation, setOpenClearAllConfirmation] = useState(false);

  const {
    isLoading, isError, data,
  } = useQuery('getCurrencyAccounts', StatementsService.getStatementAccounts, {
    refetchOnWindowFocus: false,
  });

  const {
    data: forwardsReasons,
  } = useQuery('getForwardsReasons', FXTradeService.getForwardsReasons, {
    refetchOnWindowFocus: false,
  });

  const {
    allowedSellCurrenciesOption,
    allowedBuyCurrenciesOption,
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
    sellCurrencyTextFieldValue,
    setSellCurrencyTextFieldValue,
    buyCurrencyTextFieldValue,
    setBuyCurrencyTextFieldValue,
    fixedSide,
    setFixedSide,
    indicativeRate,
    setIndicativeRate,
    pageLoading,
    setPageLoading,
    rateLoading,
    updateCounterSideAmount,
    insufficientFunds,
    availableForwardsDates,
    valueDatesLoading,
    swapCurrencies,
    handleClearAll,
    availableBuyCurrencies,
    availableSellCurrencies,
    manualResetForm,
  } = useInputForwardTrade(form, setDirectInvestment, setSettledPhysically);

  const handleSetSellAccount = (e: any) => {
    form.setFieldValue('selectedSellCurrencyAccount', data?.currencyAccounts.find((ca) => ca.id === e.target.value));
  };

  const handleSetBuyAccount = (e: any) => {
    form.setFieldValue('selectedBuyCurrencyAccount', data?.currencyAccounts.find((ca) => ca.id === e.target.value));
  };

  const [showValueOrPlaceHolder, setShowValueOrPlaceholder] = useState<string | undefined>(form.values.forwardReason || 'placeholder');

  useEffect(() => {
    setShowValueOrPlaceholder(form.values.forwardReason || 'placeholder');
  }, [form.values.forwardReason]);

  const calculateDisableDates = (_date: Moment) => {
    const formattedDate = _date.format('YYYY-MM-DD');
    return (!availableForwardsDates?.includes(formattedDate));
  };

  const handleDateChange = (_date: Moment) => {
    if (_date) {
      form.setFieldValue('valueDate', _date.format('YYYY-MM-DD'));
    }
  };

  const {
    timer: timerForRate,
    setTimer,
    liveRate,
    setLiveRate,
    liveRateStatus,
    setLiveRateStatus,
    quoteLiveRate,
  } = useQuoteTradeRate(fixedSide, validatedTrade, form.values);

  const initialMarginEstimateOptions = [
    {
      label: (
        <Box display="flex">
          <span>
            {t('sell_currency')}
            {' '}
            {form.values.sellCurrencyCode ? `(${form.values.sellCurrencyCode})` : ''}
          </span>
        </Box>),
      value: 'Sell',
      testId: 'sell-currency-initial-margin',
    },
    {
      label: (
        <Box display="flex">
          <span>
            {t('buy_currency')}
            {' '}
            {form.values.buyCurrencyCode ? `(${form.values.buyCurrencyCode})` : ''}
          </span>
        </Box>),
      value: 'Buy',
      testId: 'buy-currency-initial-margin',
    },
  ];

  const [duplicateWarningModal, setDuplicateWarningModal] = useState<boolean>(false);
  const [fixedSideIndicator, setFixedSideIndicator] = useState<'active' | 'inactive'>('inactive');
  const [loading, setLoading] = useState(false);

  const handleTradeMfa = (tradeID: string) => {
    dispatch(initiateTrade({
      type: 'FORWARDS',
      id: tradeID,
    }));
  };

  const handleInputBlur = (event: any) => {
    try {
      if (event.target.value) {
        event.target.value = parseFloat(event.target.value.replace(/,/g, '')).toFixed(2);
        if (handleBlur) handleBlur(event);
      }
    } catch {
      if (handleBlur) handleBlur(event);
    }
  };

  const updateBuyAmountChanges = useCallback(
    debounce((valueStr: string, rate: number, buyCcy: string, sellCcy: string) => {
      (async () => {
        setFixedSide('Buy');
        const value = parseFloat(valueStr.toString().replace(/,/g, '')) || 0.00;
        await form.setFieldTouched('buyAmount', true);

        if (sellCcy && buyCcy && !rate) {
          form.setFieldValue('sellAmount', 0); // clear input when changing fixed side
        }

        if (sellCcy && buyCcy && rate) {
          updateCounterSideAmount('Buy', value, rate, buyCcy, sellCcy);
        }

        await form.setFieldValue('fixedAmount', value.toFixed(2));
        form.setFieldValue('fixedCurrencyCode', buyCcy);
      })();
    }, 300), [],
  );

  const updateSellAmountChanges = useCallback(
    debounce((valueStr: string, rate: number, buyCcy: string, sellCcy: string) => {
      (async () => {
        setFixedSide('Sell');
        const value = parseFloat(valueStr.toString().replace(/,/g, '')) || 0.00;
        form.setFieldTouched('sellAmount', true);

        if (sellCcy && buyCcy && !rate) {
          form.setFieldValue('buyAmount', 0); // clear opposite input when changing fixed side
        }

        if (sellCcy && buyCcy && rate) { updateCounterSideAmount('Sell', value, rate, buyCcy, sellCcy); }

        form.setFieldValue('fixedAmount', value.toFixed(2));
        form.setFieldValue('fixedCurrencyCode', sellCcy);
      })();
    }, 300), [],
  );

  const handleBuyAmountChange = async (event: any) => {
    setFixedSideIndicator('active');
    await form.setFieldValue('buyAmount', event.target.value, true);
    updateBuyAmountChanges(event.target.value, indicativeRate?.rate || 0,
      form.values.buyCurrencyCode, form.values.sellCurrencyCode);
  };

  const handleSellAmountChange = async (event: any) => {
    setFixedSideIndicator('active');
    await form.setFieldValue('sellAmount', event.target.value, true);
    updateSellAmountChanges(event.target.value, indicativeRate?.rate || 0,
      form.values.buyCurrencyCode, form.values.sellCurrencyCode);
  };

  const handleValidateTrade = (): void => {
    validateTrade(form.values);
  };

  useEffect(() => {
    const rate = liveRate ? liveRate.rate : indicativeRate?.rate;

    setFixedSide(form.values.fixedCurrencyCode === form.values.buyCurrencyCode ? 'Buy' : 'Sell');
    setFixedSideIndicator('active');

    if (liveRate && liveRate.rate) {
      form.setFieldValue('quoteRate', liveRate.rate);
    }

    if (liveRate && liveRate.buyAmount && liveRate.sellAmount) {
      // use buy amount / sell amount from quote endpoint
      form.setFieldValue('buyAmount', liveRate.buyAmount, true);
      form.setFieldValue('sellAmount', liveRate.sellAmount, true);
      return;
    }

    if ((rate !== 0 && rate !== undefined) && (form.values.sellCurrencyCode && form.values.buyCurrencyCode)) {
      // Indicative rate & if amount not returned
      if (fixedSide === 'Buy' && form.touched.buyAmount) {
        const value = +form.values.buyAmount.toString().replace(/,/g, '');
        updateCounterSideAmount('Buy', value, rate || 0,
          form.values.buyCurrencyCode, form.values.sellCurrencyCode);
      } else if (fixedSide === 'Sell' && form.touched.sellAmount) {
        const value = +form.values.sellAmount.toString().replace(/,/g, '');
        updateCounterSideAmount('Sell', value, rate || 0,
          form.values.buyCurrencyCode, form.values.sellCurrencyCode);
      }
    }
  }, [indicativeRate, liveRate]);

  const validateTrade = async (requestForm: TForwardsFXRequestForm) => {
    setPageLoading(true);
    try {
      const tradeRequest: TradeRequest = {
        fundingMethod: requestForm.fundingMethod,
        sellCurrencyCode: requestForm.sellCurrencyCode,
        buyCurrencyCode: requestForm.buyCurrencyCode,
        fixedCurrencyCode: requestForm.fixedCurrencyCode,
        fixedAmount: requestForm.fixedAmount,
        valueDate: requestForm.valueDate,
        tradeType: TradeType.FORWARD_FIXED,
      };

      const validatedTradeResult = await FXTradeService.postValidateTradeRequest(tradeRequest, 'FORWARDS');

      form.setFieldValue('tradeId', validatedTradeResult.id);
      setValidatedTrade(validatedTradeResult);

      setPageLoading(false);
      if (validatedTradeResult.potentialDuplicates.length > 0) {
        setDuplicateWarningModal(true);
      } else {
        handleTradeMfa(validatedTradeResult.id);
      }
    } catch (e) {
      sb.trigger(e.response?.data.error
        || e.message
        || t('sorry_we_could_not_validate_this_trade'), 'error');

      logError({ action: 'Error validating trade', error: e });
      setValidatedTrade(undefined);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (liveRateStatus === 'failed') {
      setValidatedTrade(undefined);
      setMfaVerified(false);
    }
  }, [liveRateStatus]);

  useEffect(() => {
    if (authyState.status === 'SUCCESS' && authyState.type?.type === 'FORWARDS'
    ) {
      if (liveRateStatus !== 'loading') {
        handleSuccessfulAuthy();
      }
    }

    if (authyState.status === 'ERROR' && authyState.type?.type === 'FORWARDS') {
      setValidatedTrade(undefined);
      setMfaVerified(false);
    }

    if (authyState.status === undefined && authyState.type?.type === undefined && !mfaVerified) {
      setValidatedTrade(undefined);
    }
  }, [authyState.status]);

  const handleSuccessfulAuthy = () => {
    setMfaVerified(true);
    setTimeout(() => { // Timeout to wait for trade status updated in DB
      setLiveRateStatus('loading');
      quoteLiveRate();
    }, 3000);
  };

  const handleBookTrade = async (): Promise<void> => {
    try {
      setLiveRateStatus('initiatedBookTrade');
      const executeTradeRequest = {
        instructionId: String(liveRate?.instructionId),
        tradeDraftId: String(validatedTrade?.id),
      };

      const bookingResult = await FXTradeService.postExecuteTradeBooking(executeTradeRequest, 'FORWARDS');
      const {
        initialMarginSide, initialMarginPercentage, buyCurrencyCode, sellCurrencyCode,
      } = form.values;
      const isBuy = initialMarginSide === 'Buy';
      const amount = isBuy ? bookingResult?.buyAmount : bookingResult?.sellAmount;
      const currencyCode = isBuy ? buyCurrencyCode : sellCurrencyCode;

      if (amount != null && initialMarginPercentage != null) {
        const initialMargin = (initialMarginPercentage / 100) * amount;
        const formattedInitialMargin = formatCurrency(currencyCode, initialMargin);
        form.setFieldValue('initialMarginAmount', formattedInitialMargin);
      }

      setLiveRateStatus('successBookTrade');

      setBookedTrade(bookingResult);

      sb.trigger(t('forward_trade_has_been_booked_successfully'), 'success');
      logEvent({ action: 'Successfully booked spot trade' });
      logEvent({
        action: 'Book Trade Successfully',
        detail: {
          buyCurrency: bookingResult.buyCurrency,
          sellCurrency: bookingResult.sellCurrency,
        },
      });
      setDrawerState('ConfirmTrade');
    } catch (error) {
      const errorMessage = error?.response?.data?.error || error.message || t('there_was_an_error_with_your_booking_please_request_new_live_rate');
      sb.trigger(errorMessage, 'error');
      logError({ action: 'Error booking trade', error });
      setLiveRateStatus('failedBookTrade');
    }
  };

  const rateType = () => (liveRateStatus === 'timeout'
    ? 'Expired'
    : 'Live Rate');

  useEffect(() => {
    const {
      initialMarginSide, initialMarginPercentage, buyAmount, sellAmount, buyCurrencyCode, sellCurrencyCode,
    } = form.values;

    const isBuy = initialMarginSide === 'Buy';
    const amount = isBuy ? buyAmount : sellAmount;
    const currencyCode = isBuy ? buyCurrencyCode : sellCurrencyCode;

    if (amount && initialMarginPercentage) {
      // Remove commas and parse to float
      const formattedAmount = parseFloat(amount.toString().replace(/,/g, ''));
      const calculatedAmount = (initialMarginPercentage / 100) * formattedAmount;

      // Format the calculated margin amount
      const formattedInitialMargin = formatCurrency(currencyCode, calculatedAmount);

      form.setFieldValue('initialMarginAmount', formattedInitialMargin);
    }
  }, [
    form.values.initialMarginSide,
    form.values.initialMarginPercentage,
    form.values.buyAmount,
    form.values.sellAmount,
    form.values.buyCurrencyCode,
    form.values.sellCurrencyCode,
  ]);

  const handleAcknowledgeDuplicate = () => {
    setDuplicateWarningModal(false);
    if (validatedTrade) {
      const acknowledgementRequest: UserAcknowledgmentRequest = {
        itemId: validatedTrade?.id,
        type: UserAcknowledgmentType.TRADE,
        content: {
          tradeId: validatedTrade?.id,
          potentialDuplicates: (validatedTrade as TradeSubmissionDto)?.potentialDuplicates,
          message: 'Spot trade potentially duplicated.',
        },
      };
      AuthService.postUserAcknowlegement(acknowledgementRequest);

      handleTradeMfa(validatedTrade.id);
    }
  };

  const formatInitialMarginCurrency = () => {
    const {
      initialMarginSide, initialMarginAmount, sellCurrencyCode, buyCurrencyCode,
    } = form.values;

    // Determine the currency code based on the margin side
    const currencyCode = initialMarginSide === 'Sell' ? sellCurrencyCode : buyCurrencyCode;
    return `${initialMarginAmount} ${currencyCode}`;
  };

  useEffect(() => {
    const {
      defaultBuyCurrencyAccount, defaultSellCurrencyAccount,
    } = form.values;

    if (defaultBuyCurrencyAccount !== undefined && form.values.buyCurrencyCode !== '') {
      form.setFieldValue('selectedBuyCurrencyAccount', defaultBuyCurrencyAccount);
    }

    if (defaultSellCurrencyAccount !== undefined && form.values.sellCurrencyCode !== '') {
      form.setFieldValue('selectedSellCurrencyAccount', defaultSellCurrencyAccount);
    }

    if (form.values.sellCurrencyCode === '') {
      form.setFieldValue('selectedSellCurrencyAccount', undefined);
      form.setFieldValue('defaultSellCurrencyAccount', undefined);
    }
    if (form.values.buyCurrencyCode === '') {
      form.setFieldValue('selectedBuyCurrencyAccount', undefined);
      form.setFieldValue('defaultBuyCurrencyAccount', undefined);
    }
  }, [form.values.defaultBuyCurrencyAccount, form.values.defaultSellCurrencyAccount,
    form.values.sellCurrencyCode, form.values.buyCurrencyCode]);

  const dropdownItems = forwardsReasons?.map((option) => (
    <MenuItem
      key={option.name}
      value={option.name}
    >
      {t(option.name)}
    </MenuItem>
  )) ?? [];

  const dynamicMarginLeft = (rateLoading || indicativeRate) ? '10px' : '33px';

  if (loading) return <DrawerBackdropLoader display text={t('booking_spot_trade')} />;

  const valueDateWarning = availableForwardsDates
    && availableForwardsDates.length > 0
    && !form.values.valueDate
    && form.values.fixedAmount !== 0
    && form.values.buyCurrencyCode !== ''
    && form.values.sellCurrencyCode !== ''
    && form.errors.buyAmount === undefined
    && form.errors.sellAmount === undefined ? (
      <div style={{
        color: '#cf752f', fontWeight: 400, fontSize: '11px', marginTop: '4px',
      }}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        {' '}
        {t('You')}
        {' '}
        <strong>{t('must')}</strong>
        {' '}
        {t('select_a_value_date')}
      </div>
    ) : <></>;

  const noAvailableValueDateWarning = !valueDatesLoading
  && availableForwardsDates?.length === 0
  && form.errors.fixedAmount === undefined
  && form.errors.buyCurrencyCode === undefined
  && form.errors.sellCurrencyCode === undefined ? (
    <div style={{
      color: 'rgb(185, 86, 86)', fontWeight: 400, fontSize: '11px', marginTop: '4px',
    }}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
      {' '}
      {t('no_value_dates_available')}
    </div>
    ) : <></>;

  return (
    <Box className={styles.form}>
      {pageLoading && (<BackdropLoader testId="backdrop-loader" />)}
      {!pageLoading && (
      <>
        <Box display="flex" justifyContent="space-between" flexDirection="column" marginTop="15px" className={styles.minHeight}>
          <Box padding="10px">
            <Box style={{ height: '100px' }}>
              <Box style={{ padding: '5px 0px 5px 20px', borderRadius: '10px', border: '1px solid #eee' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.sellCurrencyCode ? '104px' : 'fit-content' }}>
                    <AutocompleteDropDown
                      hideFlag
                      backgroundColor="transparent"
                      name="sellCurrencyCode"
                      testId="bankCurrencyDropdown"
                      fontSize="22px"
                      fontWeight={600}
                      type="CODE"
                      options={selectedBuyCurrencyOption?.code ? availableSellCurrencies?.filter(
                        (item) => item.code !== selectedBuyCurrencyOption?.code,
                      ) : allowedSellCurrenciesOption}
                      setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                        form.setFieldValue('selectedSellCurrencyAccount', undefined);
                        form.setFieldValue(field, value, shouldValidate);

                        setIndicativeRate({
                          valueDate: '',
                          rate: 0,
                          initialMarginPercentage: 0,
                          initialMarginDueDate: '',
                        });

                        form.setFieldValue('sellAmount', 0);
                        if (value === '' || value === undefined) {
                          manualResetForm();
                        } else {
                          form.setFieldValue('buyAmount', 0);
                          form.setFieldValue('valueDate', null);
                          form.validateForm();
                        }
                      }}
                      value={form.values.sellCurrencyCode}
                      touched={form.touched.sellCurrencyCode}
                      currentOption={selectedSellCurrencyOption}
                      setCurrentOption={setSelectedSellCurrencyOption}
                      textFieldValue={sellCurrencyTextFieldValue}
                      setTextFieldValue={setSellCurrencyTextFieldValue}
                      disabled={Boolean(validatedTrade)}
                      placeholder={t('sell_currency')}
                    />
                  </div>
                  <div style={{ display: 'flex', width: '50%', paddingRight: '30px' }}>
                    <NumberFormat
                      thousandSeparator
                      className={clsx(
                        styles.red,
                      )}
                      id="sellAmount"
                      name="sellAmount"
                      value={form.values.sellAmount && form.values.sellCurrencyCode !== '' ? form.values.sellAmount : ''}
                      disabled={Boolean(validatedTrade)
                              || rateLoading || (form.values.buyCurrencyCode === '' || form.values.sellCurrencyCode === '')}
                      onChange={handleSellAmountChange}
                      onBlurCapture={handleInputBlur}
                      disableUnderline
                      autoComplete="off"
                      placeholder="0.00"
                      type="text"
                      customInput={Input}
                      inputProps={{
                        'data-testid': 'sellAmount',
                        'font-size': '22px',
                        style: { textAlign: 'right' },
                      }}
                      style={{ flexGrow: 1, marginTop: '10px' }}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                </Box>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.selectedSellCurrencyAccount ? '50%' : '207px' }}>
                    <Dropdown
                      data={filterByCurrencyAccountToData(data?.currencyAccounts ?? [], false, false, form.values.sellCurrencyCode)}
                      handleChange={(e) => handleSetSellAccount(e)}
                      helperText=""
                      placeholderText={`${t('select_Account')} | £0.00`}
                      data-testid="sell-account"
                      selectName="sell-account"
                      selectValue={form.values.selectedSellCurrencyAccount?.id ? form.values.selectedSellCurrencyAccount?.id : form.values?.defaultSellCurrencyAccount?.id}
                      hideFlag
                      backgroundColor="transparent"
                      disabled={!form.values.sellCurrencyCode || mfaVerified}
                      fx
                    />
                  </div>
                  <div style={{ width: '50%', padding: '15px 30px 0px 0px' }}>
                    <InputLabel className={clsx(styles.error, styles.red)}>
                      {form.touched.sellAmount ? form.errors.sellAmount : ''}
                    </InputLabel>
                  </div>
                </div>
              </Box>
            </Box>

            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
            }}
            >
              {(rateLoading || (!validatedTrade && form.values.buyCurrencyCode
               && form.values.sellCurrencyCode && form.values.fixedAmount > 0 && form.values.valueDate && !!(indicativeRate?.rate))) && (
               <Rate
                 type={!validatedTrade ? 'Indicative' : rateType()}
                 creditingCurrency={selectedSellCurrencyOption?.code || ''}
                 debitingCurrency={selectedBuyCurrencyOption?.code || ''}
                 rate={indicativeRate ? indicativeRate.rate : 0.00}
                 loading={rateLoading}
                 mfaVerified={mfaVerified}
               />
              )}
              {validatedTrade && (
                <Rate
                  type={liveRate ? rateType() : 'Indicative'}
                  creditingCurrency={selectedSellCurrencyOption?.code || ''}
                  debitingCurrency={selectedBuyCurrencyOption?.code || ''}
                  rate={liveRate?.rate || indicativeRate?.rate || 0.00}
                  loading={liveRateStatus === 'loading' || liveRateStatus === 'initiated'}
                  mfaVerified={mfaVerified}
                />
              )}

              <div style={{ marginLeft: dynamicMarginLeft }}>
                <IconButton
                  title="Swap"
                  icon={activeIcon ? faArrowUp : faArrowDown}
                  size="medium"
                  aria-label="Swap"
                  disabled={(mfaVerified || (!form.values.sellCurrencyCode || !form.values.buyCurrencyCode) || rateLoading)}
                  onClick={() => {
                    handleChangeActive();
                    swapCurrencies();
                  }}
                  className={mfaVerified ? styles.hideSwapBtn : styles.swapButton}
                />
              </div>
            </div>

            <Box style={{ marginTop: '-8px' }}>
              <Box style={{ padding: '5px 0px 5px 20px', borderRadius: '10px', border: '1px solid #eee' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.buyCurrencyCode ? '104px' : 'fit-content' }}>
                    <AutocompleteDropDown
                      hideFlag
                      fontSize="22px"
                      fontWeight={600}
                      backgroundColor="transparent"
                      name="buyCurrencyCode"
                      testId="bankCurrencyDropdown"
                      type="CODE"
                      options={selectedSellCurrencyOption?.code ? availableBuyCurrencies?.filter(
                        (item) => item.code !== selectedSellCurrencyOption?.code,
                      ) : allowedBuyCurrenciesOption}
                      setFieldValue={(field: string, value: any, shouldValidate?: boolean) => {
                        form.setFieldValue('selectedBuyCurrencyAccount', undefined);
                        form.setFieldValue(field, value, shouldValidate);

                        setIndicativeRate({
                          valueDate: '',
                          rate: 0,
                          initialMarginPercentage: 0,
                          initialMarginDueDate: '',
                        });
                        form.setFieldValue('buyAmount', 0);

                        if (value === '' || value === undefined) {
                          manualResetForm();
                        } else {
                          form.setFieldValue('sellAmount', 0);
                          form.setFieldValue('valueDate', null);
                          form.validateForm();
                        }
                      }}
                      value={form.values.buyCurrencyCode}
                      touched={form.touched.buyCurrencyCode}
                      errors={form.errors.buyCurrencyCode}
                      currentOption={selectedBuyCurrencyOption}
                      setCurrentOption={setSelectedBuyCurrencyOption}
                      textFieldValue={buyCurrencyTextFieldValue}
                      setTextFieldValue={setBuyCurrencyTextFieldValue}
                      disabled={Boolean(validatedTrade)}
                      placeholder={t('buy_currency')}
                    />
                  </div>
                  <div style={{ display: 'flex', width: '50%', paddingRight: '30px' }}>
                    <NumberFormat
                      thousandSeparator
                      customInput={Input}
                      className={styles.green}
                      id="buyAmount"
                      name="buyAmount"
                      value={form.values.buyAmount && form.values.buyCurrencyCode !== '' ? form.values.buyAmount : ''}
                      disabled={Boolean(validatedTrade)
                        || rateLoading || (form.values.buyCurrencyCode === '' || form.values.sellCurrencyCode === '')}
                      onChange={handleBuyAmountChange}
                      onBlurCapture={handleInputBlur}
                      disableUnderline
                      autoComplete="off"
                      placeholder="0.00"
                      type="text"
                      inputProps={{
                        'data-testid': 'buyAmount',
                        'font-size': '22px',
                        style: { textAlign: 'right' },
                      }}
                      style={{ flexGrow: 1, marginTop: '10px' }}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                </Box>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: form.values.selectedBuyCurrencyAccount ? '50%' : '207px' }}>
                    <Dropdown
                      data={filterByCurrencyAccountToData(data?.currencyAccounts ?? [], false, false, form.values.buyCurrencyCode)}
                      handleChange={(e) => handleSetBuyAccount(e)}
                      helperText=""
                      placeholderText={`${t('select_Account')} | £0.00`}
                      data-testid="buy-account"
                      selectName="buy-account"
                      selectValue={form.values.selectedBuyCurrencyAccount?.id ? form.values.selectedBuyCurrencyAccount?.id : form.values.defaultBuyCurrencyAccount?.id}
                      hideFlag
                      backgroundColor="transparent"
                      disabled={!form.values.buyCurrencyCode || mfaVerified}
                      fx
                    />
                  </div>
                  <div style={{ width: '50%', padding: '15px 30px 0px 0px' }}>
                    <InputLabel className={clsx(styles.error, styles.red)}>
                      {form.touched.buyAmount ? form.errors.buyAmount : ''}
                    </InputLabel>
                  </div>
                </div>
              </Box>
              {!validatedTrade && !mfaVerified ? (
                <Box style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                  <Box>
                    <Box className={styles.valueDateTitle}>
                      {t('forward_type')}
                    </Box>
                    <APTooltip
                      padding="8px"
                      placement="bottom-start"
                      title={
                          (
                            <div style={{
                              width: '314px',
                              height: '40px',
                              fontWeight: 400,
                              fontSize: '12px',
                              color: '#F5EFF7',
                              lineHeight: '16px',
                            }}
                            >
                              <span style={{ whiteSpace: 'break-spaces' }}>
                                {t('forward_type_tooltip')}
                              </span>
                            </div>
                          )
                        }
                    >
                      <Select
                        data-testid="fixed-select"
                        onChange={() => { }}
                        value="Fixed"
                        disableUnderline
                        disabled
                        className={styles.dropdown}
                        IconComponent={() => (
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{
                              marginRight: '15px', marginTop: '1px',
                            }}
                          />
                        )}
                      >
                        <MenuItem value="Fixed">{t('fixed')}</MenuItem>
                      </Select>
                    </APTooltip>
                  </Box>
                  <Box>
                    <Box className={styles.valueDateTitle}>
                      {t('value_date')}
                      {' '}
                      {valueDatesLoading ? <Loader testId="valueDateLoading" size={8} style={{ marginLeft: '10px' }} /> : null}
                    </Box>
                    <APDatePicker
                      fullWidth
                      label=""
                      autoOk
                      language={i18n.language}
                      className="datePicker"
                      onChange={handleDateChange}
                      value={form.values.valueDate || null} // null to fix error message shown when empty
                      loadingIndicator={<Loader testId="" />}
                      disabled={
                        valueDatesLoading
                        || availableForwardsDates?.length === 0
                        || form.values.buyCurrencyCode === ''
                        || form.values.sellCurrencyCode === ''
                        || !form.values.selectedBuyCurrencyAccount
                        || !form.values.selectedSellCurrencyAccount
                        || ((form.values.buyAmount === 0 || form.errors.buyAmount !== undefined)
                        && (form.values.sellAmount === 0 || form.errors.sellAmount !== undefined))
                      }
                      placeholder={t('select_value_date')}
                      shouldDisableDate={calculateDisableDates}
                      style={{ width: '258px', borderRadius: '4px' }}
                    />
                    {valueDateWarning}
                    {noAvailableValueDateWarning}
                  </Box>
                </Box>
              ) : null}
              {!validatedTrade && !mfaVerified && (
              <div>
                <Divider className={styles.verticalDivider} />
              </div>
              )}

              {indicativeRate && indicativeRate?.initialMarginPercentage > 0 && !validatedTrade && !mfaVerified && (
                <Box className={styles.card} style={{ marginTop: '25px' }}>
                  <Box style={{ padding: '20px' }}>
                    <Typography style={{ fontWeight: 600, fontSize: '16px', marginBottom: '5px' }}>
                      {t('initial_margin_estimate')}
                    </Typography>
                    <div>
                      <RadioButtonGroup
                        id="test"
                        className={styles.radioButtons}
                        testId="initial-margin"
                        onChange={(event) => {
                          form.setFieldValue('initialMarginSide', event.target.value);
                        }}
                        value={form.values.initialMarginSide}
                        items={initialMarginEstimateOptions}
                      />
                      <Divider />

                      <Box style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>
                          {t('initial_margin_percentage')}
                          :
                        </Typography>
                        <Box>
                          {form.values.initialMarginPercentage}
                          % |
                          {' '}
                          {formatInitialMarginCurrency()}
                        </Box>
                      </Box>
                      <Box style={{
                        marginTop: '10px', display: 'flex', justifyContent: 'space-between', marginBottom: '15px',
                      }}
                      >
                        <Typography>
                          {t('due_date')}
                          :
                        </Typography>
                        <Box>
                          {form.values.initialMarginDueDate}
                        </Box>
                      </Box>
                    </div>
                  </Box>
                  <StyledDivider />
                  <FundingMethod
                    show
                    padAllowed={padAllowed}
                    form={form}
                    editable={!(validatedTrade && mfaVerified)}
                    insufficientFunds={insufficientFunds()}
                  />
                </Box>
              )}
              {!validatedTrade && !mfaVerified ? (
                <Box className={styles.card} style={{ marginTop: '25px' }}>
                  <Box style={{ padding: '20px' }}>
                    <Typography style={{ fontWeight: 600, fontSize: '16px', marginBottom: '20px' }}>
                      {t('regulatory_disclosure')}
                    </Typography>
                    <Select
                      onChange={(e) => { form.setFieldValue('forwardReason', e.target.value); }}
                      value={showValueOrPlaceHolder}
                      id="dropdown"
                      fullWidth
                      disableUnderline
                      className={styles.forwardReasoningDropdown}
                      placeholder={t('select_forward_reasoning')}
                    >
                      {[
                        <MenuItem value="placeholder" disabled>
                          <Typography variant="subtitle1" className={styles.placeholder}>
                            {t('select_forward_reasoning')}
                          </Typography>
                        </MenuItem>,
                        ...dropdownItems,
                      ]}
                    </Select>

                    <div className={styles.checkbox}>
                      <Checkbox
                        checked={directInvestment}
                        label={t('direct_investment_checkbox')}
                        onChange={(event) => {
                          setDirectInvestment(event.target.checked);
                          form.setFieldValue('directInvestment', event.target.checked);
                        }}
                      />
                    </div>
                    <div className={styles.settledPhysicallyCheckbox}>
                      <Checkbox
                        checked={settledPhysically}
                        label={t('settled_physically')}
                        onChange={(event) => {
                          setSettledPhysically(event.target.checked);
                          form.setFieldValue('settledPhysically', event.target.checked);
                        }}
                      />
                      <APTooltip
                        padding="8px"
                        style={{ width: 'fit-content' }}
                        placement="bottom-start"
                        title={
                        (
                          <div style={{
                            width: '439px',
                            height: '56px',
                            fontWeight: 400,
                            fontSize: '12px',
                            color: '#F5EFF7',
                            lineHeight: '16px',
                          }}
                          >
                            <span style={{ whiteSpace: 'break-spaces' }}>
                              {t('settled_physically_tooltip')}
                            </span>
                          </div>
                        )
                      }
                      >
                        <Box display="inline-block">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Box>
                      </APTooltip>
                    </div>
                  </Box>
                </Box>
              ) : null}

              {mfaVerified && validatedTrade ? (
                <FundingMethod
                  show={form.values.buyAmount !== 0 || form.values.sellAmount !== 0}
                  padAllowed={padAllowed}
                  form={form}
                  editable={!(validatedTrade && mfaVerified)}
                  insufficientFunds={insufficientFunds()}
                  validatedTrade
                />
              ) : null}

            </Box>
          </Box>
          <Actions
            timerForRate={timerForRate}
            form={form}
            setValidatedTrade={setValidatedTrade}
            setMfaVerified={setMfaVerified}
            setLiveRate={setLiveRate}
            mfaVerified={mfaVerified}
            handleValidateTrade={handleValidateTrade}
            liveRateStatus={liveRateStatus}
            pageLoading={pageLoading}
            rateLoading={rateLoading}
            valueDateLoading={valueDatesLoading}
            handleBookTrade={handleBookTrade}
            quoteLiveRate={quoteLiveRate}
            insufficientFunds={insufficientFunds()}
            validatedTrade={validatedTrade}
            handleClearAll={() => {
              setOpenClearAllConfirmation(true);
            }}
          />
        </Box>

        <DuplicatedTradeModal
          open={duplicateWarningModal}
          onClose={() => { setDuplicateWarningModal(false); }}
          potentialDuplicates={(validatedTrade as TradeSubmissionDto)?.potentialDuplicates || []}
          handleAcknowledgeDuplicate={handleAcknowledgeDuplicate}
        />
        <ConfirmationModal
          variant="negative"
          bothButtons
          title={(
            <span>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="lg"
                color="#CF752F"
                className="titleIcon"
              />
              {' '}
              <span style={{ marginLeft: '4px', marginTop: '2px' }}>
                {' '}
                {t('are_you_sure')}
              </span>
            </span>
            )}
          open={openClearAllConfirmation}
          onExit={() => setOpenClearAllConfirmation(false)}
          onConfirm={() => {
            handleClearAll();
            setOpenClearAllConfirmation(false);
            sb.trigger(t('cleared_all_input'), 'info');
          }}
          exitButtonText={t('cancel')}
          content={(
            <p style={{
              fontSize: '13px', fontWeight: 400, color: '#212529BF',
            }}
            >
              {t('you_will_be_clearing_all_input_from_this_form')}
            </p>
            )}
          width="600px"
          buttonSpacing="space-between"
          confirmButtonWidth={458}
          cancelButtonWidth={44}
          confirmButtonText={t('clear')}
        />
      </>
      )}
    </Box>
  );
};

export default InputForwardTrade;
