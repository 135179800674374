/* eslint-disable max-lines-per-function */
import React, { SetStateAction, useEffect, useState } from 'react';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useForm from 'hooks/useForm';
import useLog from 'hooks/useLog';
import { useQuery } from 'react-query';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import replaceCommaIfString from 'utils/replaceCommaIfString';
import t from 'utils/translationHelper';
import * as yup from 'yup';

import {
  ExecuteTradeResultDto, TradeDto,
  TradeSubmissionDto,
} from '@alpha/fx-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Box, Divider, Typography } from '@alpha/ui-lib/ui/external';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmTrade from './ConfirmTrade';
import { initialValues, TForwardsFXRequestForm } from './formData';
import useStyles from './index.styles';
import InputForwardTrade from './InputForwardTrade';

type Props = {
  open: boolean,
  onClose?: () => void | SetStateAction<boolean>,
  heading?: string,
  setTradeBooked?: React.Dispatch<React.SetStateAction<boolean>>;
  onTradeBooked?: () => void;
}

export type TForwardsDrawState = 'InputTrade' | 'ConfirmTrade';

export type TFxBookingState =
  'loading'
  | 'initiated'
  | 'failed'
  | 'timeout'
  | 'success'
  | 'initiatedBookTrade'
  | 'failedBookTrade'
  | 'successBookTrade';

const CreateForwardTradeDrawer: React.FC<Props> = ({
  open, onClose, heading, setTradeBooked, onTradeBooked,
}) => {
  const styles = useStyles();
  const { logError } = useLog();
  const sb = useAlphaSnackbar();

  const [drawerState, setDrawerState] = useState<TForwardsDrawState>(
    'InputTrade',
  );
  const [validatedTrade, setValidatedTrade] = useState<TradeSubmissionDto | TradeDto >();
  const [bookedTrade, setBookedTrade] = useState<ExecuteTradeResultDto>();
  const [minForwardLimit, setMinForwardLimit] = useState<number>(1);
  const [maxForwardLimit, setMaxForwardLimit] = useState<number>(1000000);

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (bookedTrade && onTradeBooked) {
      onTradeBooked();
    }
  }, [onTradeBooked]);

  const onDrawerClose = async (): void => {
    setValidatedTrade(undefined);
    setBookedTrade(undefined);
    setDrawerState('InputTrade');
    // try { // comment out for now until we can differentiate between spot and forward in service
    //   const { sellCurrencyCode } = form.values;
    //   const { buyCurrencyCode } = form.values;
    //   const { indicativeRate } = form.values;
    //   const currencyPair = sellCurrencyCode.concat(buyCurrencyCode);
    // if (drawerState === 'InputTrade' && buyCurrencyCode && sellCurrencyCode && indicativeRate && !submitting) {
    //   setSubmitting(true);
    //   await FXTradeService.postQuoteNoTradeRequestAsync({
    //     currencyPair,
    //     amount: form.values.fixedAmount,
    //     indicativeRate,
    //     quoteRate: form.values.quoteRate,
    //   });
    // }
    // } catch (error) {
    //   logError({ action: 'Error calling quote-no-trade endpoint', error });
    // } finally {
    form.resetForm();
    if (onClose) { onClose(); }
    setSubmitting(false);
    // }
  };

  const newValidation = {
    fixedAmount: yup
      .number().transform(replaceCommaIfString)
      .typeError(t('amount_must_be_a_number'))
      .min(1, t('amount_too_small'))
      .required(t('please_enter_a_valid_amount')),
    buyAmount: yup
      .number().transform(replaceCommaIfString)
      .typeError(t('amount_must_be_a_number'))
      .required(t('please_enter_a_valid_amount'))
      .min(0.0, t('amount_too_small'))
      .test({
        name: 'Min and max forward limit check',
        test() {
          const {
            sellCurrencyCode, buyAmount, buyCurrencyCode, fixedCurrencyCode,
          } = this.parent;
          if (buyAmount && sellCurrencyCode && buyCurrencyCode && (buyAmount < minForwardLimit) && fixedCurrencyCode === buyCurrencyCode) {
            return this.createError({
              message: t('amount_is_below_the_minimum_forwards_limit'),
              path: 'buyAmount',
            });
          }
          if (buyAmount && sellCurrencyCode && buyCurrencyCode && (buyAmount > maxForwardLimit) && fixedCurrencyCode === buyCurrencyCode) {
            return this.createError({
              message: t('amount_is_above_the_maximum_forwards_limit'),
              path: 'buyAmount',
            });
          }
          return true;
        },
      }),
    sellCurrencyCode: yup
      .string()
      .required(t('please_select_sell_currency')),
    buyCurrencyCode: yup
      .string()
      .required(t('please_select_buy_currency')),
    forwardReason: yup
      .string()
      .required(t('forward_reason_not_set')),
    indicativeRate: yup
      .number().required(t('indicative_rate_not_set')),
    directInvestment: yup
      .boolean().oneOf([true], t('direct_investment_not_set')).required(t('direct_investment_not_set')),
    settledPhysically: yup
      .boolean().oneOf([true], t('settled_physically_not_set')).required(t('settled_physically_not_set')),
    sellAmount: yup
      .number().transform(replaceCommaIfString)
      .required(t('please_enter_a_valid_amount'))
      .typeError(t('amount_must_be_a_number'))
      .min(0.0, t('amount_too_small'))
      .test({
        name: 'Min and max forward limit check',
        test() {
          const {
            sellAmount, sellCurrencyCode, buyCurrencyCode, fixedCurrencyCode,
          } = this.parent;
          if (sellAmount && sellCurrencyCode && buyCurrencyCode && (sellAmount < minForwardLimit) && fixedCurrencyCode === sellCurrencyCode) {
            return this.createError({
              message: t('amount_is_below_the_minimum_forwards_limit'),
              path: 'sellAmount',
            });
          }
          if (sellAmount && sellCurrencyCode && buyCurrencyCode && (sellAmount > maxForwardLimit) && fixedCurrencyCode === sellCurrencyCode) {
            return this.createError({
              message: t('amount_is_above_the_maximum_forwards_limit'),
              path: 'sellAmount',
            });
          }
          return true;
        },
      }),
    initialMarginSide: yup
      .string()
      .when('initialMarginPercentage', {
        is: (initialMarginPercentage) => initialMarginPercentage > 0,
        then: yup.string().required(t('select_initial_margin_currency')),
      })
    ,
  };

  const form = useForm<TForwardsFXRequestForm>(
    initialValues,
    newValidation,
    () => { },
    true,
    false,
  );

  const { data: forwardsConfigurations, error } = useQuery('getForwardsConfigurations', () => FXTradeService.getForwardsConfigurations(), {
    refetchOnWindowFocus: false,
  });

  const accountConfigQuery = useQuery('getAccountConfig', () => FXTradeService.getAccountConfigurationWithCcys(
    form.values.sellCurrencyCode,
    form.values.buyCurrencyCode,
  ), {
    enabled: false,
    onError: (e) => {
      sb.trigger(t('could_not_load_account_configurations'), 'error');
      logError({ action: 'Error loading Account Configurations', error: e });
    },
  });

  useEffect(() => {
    if (
      open
      && form.values.sellCurrencyCode
      && form.values.buyCurrencyCode
      && forwardsConfigurations?.forwardsConfigurations
    ) {
      // Find the matching configuration
      const matchingConfiguration = forwardsConfigurations.forwardsConfigurations.find(
        (c) => (c.currencyOne === form.values.sellCurrencyCode && c.currencyTwo === form.values.buyCurrencyCode)
          || (c.currencyTwo === form.values.sellCurrencyCode && c.currencyOne === form.values.buyCurrencyCode),
      );
      if (matchingConfiguration) {
        const isCurrencyOne = form.values.fixedCurrencyCode === matchingConfiguration.currencyOne;
        const isCurrencyTwo = form.values.fixedCurrencyCode === matchingConfiguration.currencyTwo;
        // Update forward limits based on the matched configuration and fixedCurrencyCode
        if (isCurrencyOne) {
          setMinForwardLimit(matchingConfiguration.minimumVolumeCurrencyOne);
          setMaxForwardLimit(matchingConfiguration.maximumVolumeCurrencyOne);
        }
        if (isCurrencyTwo) {
          setMinForwardLimit(matchingConfiguration.minimumVolumeCurrencyTwo);
          setMaxForwardLimit(matchingConfiguration.maximumVolumeCurrencyTwo);
        }

        // const buyCcy = form.values.buyCurrencyCode === matchingConfiguration.currencyOne ? matchingConfiguration.currencyOne : matchingConfiguration.currencyTwo;
        // const sellCcy = form.values.sellCurrencyCode === matchingConfiguration.currencyTwo ? matchingConfiguration.currencyTwo : matchingConfiguration.currencyOne;

        // fixedSide - ccy - conf min + max
        //
        // Update forward limits for buy currency
        // if (buyCcy) {
        //   const isCurrencyOne = buyCcy === matchingConfiguration.currencyOne;
        //   const isCurrencyTwo = buyCcy === matchingConfiguration.currencyTwo;

        //   if (isCurrencyOne) {
        //     setMinForwardLimitCurrencyBuy(matchingConfiguration.minimumVolumeCurrencyOne);
        //     setMaxForwardLimitCurrencyBuy(matchingConfiguration.maximumVolumeCurrencyOne);
        //   } else if (isCurrencyTwo) {
        //     setMinForwardLimitCurrencyBuy(matchingConfiguration.minimumVolumeCurrencyTwo);
        //     setMaxForwardLimitCurrencyBuy(matchingConfiguration.maximumVolumeCurrencyTwo);
        //   }
        // }

        // // Update forward limits for sell currency
        // if (sellCcy) {
        //   const isCurrencyOne = sellCcy === matchingConfiguration.currencyOne;
        //   const isCurrencyTwo = sellCcy === matchingConfiguration.currencyTwo;
        //   if (isCurrencyOne) {
        //     setMinForwardLimitCurrencySell(matchingConfiguration.minimumVolumeCurrencyOne);
        //     setMaxForwardLimitCurrencySell(matchingConfiguration.maximumVolumeCurrencyOne);
        //   } else if (isCurrencyTwo) {
        //     setMinForwardLimitCurrencySell(matchingConfiguration.minimumVolumeCurrencyTwo);
        //     setMaxForwardLimitCurrencySell(matchingConfiguration.maximumVolumeCurrencyTwo);
        //   }
        // }
      }
    }
  }, [
    open,
    forwardsConfigurations,
    form.values.fixedCurrencyCode,
  ]);

  useEffect(() => {
    if (open && accountConfigQuery.data && !accountConfigQuery.data?.fxAllowed) {
      sb.trigger(t('sorry_you_do_not_have_permissions_to_create_a_new_forward'), 'info');
      logError({
        action: 'User has no permission to create new forward',
        detail: {
          reason: 'account configuration not allowed',
          accountConfig: accountConfigQuery.data,
        },
      });
      if (onClose) { onClose(); }
    }
  },
  [
    open,
    accountConfigQuery.data?.fxAllowed,
  ]);

  return (
    <BaseDrawer.Drawer
      open={open}
      onClose={onDrawerClose}
      anchor="right"
      className={styles.drawer}
      disabled={submitting}
    >
      <Box className={styles.container}>
        {drawerState === 'InputTrade' && (
          <>
            <h2 style={{ padding: '0px 10px', fontWeight: 400, display: 'flex' }}>
              <span style={{
                padding: '8px', backgroundColor: '#F7F7F7', borderRadius: '4px', alignItems: 'center', display: 'flex',
              }}
              >
                <FontAwesomeIcon icon={faCalendarAlt} style={{ color: '#1E8777', height: '16px', width: '14px' }} />
              </span>
              <span style={{ marginLeft: '8px' }}>
                {heading}
              </span>
            </h2>
            <Divider className={styles.verticalDivider} />
            <InputForwardTrade
              form={form}
              setDrawerState={setDrawerState}
              padAllowed={false}
              setBookedTrade={setBookedTrade}
              validatedTrade={validatedTrade}
              setValidatedTrade={setValidatedTrade}
              handleClose={onDrawerClose}
            />
          </>
        )}
        {drawerState === 'ConfirmTrade' && (
          <>
            <h2>
              <Typography className={styles.bookedHeading}>
                <FontAwesomeIcon icon={faCheckSquare as IconProp} className={styles.icon} />
                {' '}
                {t('forward_trade_booked')}
              </Typography>
            </h2>
            <Divider className={styles.verticalDivider} />
            <ConfirmTrade
              form={form}
              handleClose={onDrawerClose}
              setDrawerState={setDrawerState}
              bookedTrade={bookedTrade}
              setTradeBooked={setTradeBooked}
            />
          </>
        )}
      </Box>
    </BaseDrawer.Drawer>
  );
};

export default CreateForwardTradeDrawer;
