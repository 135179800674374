import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import t from 'utils/translationHelper';

import { ValidateBeneficiaryRequest } from '@alpha/bene-dtos';
import { Typography } from '@alpha/ui-lib/ui/external';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AutocompleteDropDown, { IOption } from '../../../../../../components/AutocompleteDropDown/AutocompleteDropDown';
import InputAndLabel from '../../../../../../components/Inputs/Beneficiary/InputAndLabel';
import { TCountries } from '../../../../../../models/countries';
import useStyles from '../styles';

interface IProps {
  form: FormikProps<ValidateBeneficiaryRequest>;
  countries: TCountries;
  disabled: boolean;
  selectedBeneficiaryCountryCode?: string;
  addressOnly?: boolean
  requiresAddress?: boolean
}

const Address: React.FC<IProps> = ({
  form, countries, disabled, selectedBeneficiaryCountryCode, addressOnly, requiresAddress,
}) => {
  const styles = useStyles();
  const [
    currentBeneficiaryCountryCode,
    setCurrentBeneficiaryCountryCode,
  ] = useState<IOption>({ name: '', code: '' });
  const [currentBeneficiaryCountryCodeTextFieldValue, setCurrentBeneficiaryCountryCodeTextFieldValue] = useState<string>('');
  const [countryRequirementsResponse, setCountryRequirementsResponse] = useState<boolean>();

  const updateSelectedCountryCode = (countryCode?: string) => {
    if (countryCode && countries.length) {
      const selectedCountry = countries.find(
        (c) => c.code === countryCode,
      );

      if (selectedCountry) {
        setCurrentBeneficiaryCountryCode(selectedCountry);
        setCurrentBeneficiaryCountryCodeTextFieldValue(selectedCountry.name);
      }
    }
  };

  useEffect(() => {
    updateSelectedCountryCode(form.values.countryCode);
  }, [countries, form.values.countryCode]);

  useEffect(() => {
    const countryCodeSelected = form.values.countryCode || selectedBeneficiaryCountryCode || form.values.bankCountryCode;
    if (countryCodeSelected) {
      updateSelectedCountryCode(countryCodeSelected);
    }
  }, [selectedBeneficiaryCountryCode, form.values.countryCode, form.values.bankCountryCode]);

  useEffect(async () => {
    if (form.values.bankCountryCode && form.values.currencyCode) {
      const response = await BeneficiariesService.getCountryRequirements([{ countryCode: form.values.bankCountryCode, currencyCode: form.values.currencyCode }]);
      setCountryRequirementsResponse(response[0].requiresAddress);
    }
  }, [form.values.bankCountryCode, form.values.currencyCode]);

  return (
    <div className="address">
      <Typography style={{ marginBottom: '24px' }}>
        {countryRequirementsResponse
          ? (
            <div style={{ color: '#CF752F' }}>
              <FontAwesomeIcon icon={faExclamationCircle} style={{ marginRight: '4px' }} />
              {t('please_do_not_input_the_beneficiary_bank_address')}
            </div>
          ) : null}
      </Typography>
      <div className={styles.inputWrapper}>
        <div>
          <InputAndLabel
            label={t('address_line_1')}
            name="addressLine1"
            id="addressLine1"
            testId="addressLine1"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.addressLine1}
            disabled={disabled}
            error={
              form.touched.addressLine1
              && Boolean(form.errors.addressLine1)
            }
            helperText={
              form.touched.addressLine1
              && form.errors.addressLine1
            }
          />
        </div>
        <div>
          <InputAndLabel
            label={t('address_line_2_optional')}
            name="addressLine2"
            id="addressLine2"
            testId="addressLine2"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.addressLine2}
            disabled={disabled}
            error={
              form.touched.addressLine2
              && Boolean(form.errors.addressLine2)
            }
            helperText={
              form.touched.addressLine2
              && form.errors.addressLine2
            }
          />
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <div>
          <InputAndLabel
            label={requiresAddress ? t('city_town') : t('city_town_optional')}
            name="city"
            id="city"
            testId="city"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.city}
            disabled={disabled}
            error={
              form.touched.city
              && Boolean(form.errors.city)
            }
            helperText={
              form.touched.city
              && form.errors.city
            }
          />
        </div>
        <div>
          <InputAndLabel
            label={requiresAddress ? t('country_state_province') : t('country_state_province_optional')}
            name="state"
            id="state"
            testId="state"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.state}
            disabled={disabled}
            error={
              form.touched.state
              && Boolean(form.errors.state)
            }
            helperText={
              form.touched.state
              && form.errors.state
            }
          />
        </div>
      </div>
      <div className={styles.inputWrapper}>
        <div>
          <InputAndLabel
            label={requiresAddress ? t('zip_postal_code') : t('zip_postal_code_optional')}
            name="zip"
            id="zip"
            testId="zip"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.zip}
            disabled={disabled}
            error={
              form.touched.zip
              && Boolean(form.errors.zip)
            }
            helperText={
              form.touched.zip
              && form.errors.zip
            }
          />
        </div>
        <div>
          {
            disabled
              ? (
                <InputAndLabel
                  label={t('country')}
                  name="country"
                  id="country"
                  testId="country"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.countryCode}
                  disabled={disabled}
                  error={
                    form.touched.countryCode
                    && Boolean(form.errors.countryCode)
                  }
                  helperText={
                    form.touched.countryCode
                    && form.errors.countryCode
                  }
                />
              )
              : (
                <>
                  <Typography variant="subtitle1">{t('country')}</Typography>
                  <AutocompleteDropDown
                    disabled={disabled}
                    name="countryCode"
                    testId="beneficiaryCountryCodeDropdown"
                    options={countries}
                    setFieldValue={form.setFieldValue}
                    value={form.values.countryCode}
                    touched={form.touched.countryCode}
                    errors={form.errors.countryCode}
                    currentOption={currentBeneficiaryCountryCode}
                    setCurrentOption={setCurrentBeneficiaryCountryCode}
                    textFieldValue={currentBeneficiaryCountryCodeTextFieldValue}
                    setTextFieldValue={setCurrentBeneficiaryCountryCodeTextFieldValue}
                  />
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default Address;
