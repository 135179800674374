export enum UserRole {
  PAYMENTS_INPUTTER = 'Payments Inputter',
  BENEFICIARY_INPUTTER = 'Beneficiary Inputter',
  PAYMENTS_APPROVER = 'Payments Approver',
  PAYMENTS_APPROVER_OWN = 'Payments Approver Own',
  PAYMENT_FILE_INPUTTER = 'Payment File Inputter',
  BENEFICIARY_APPROVER = 'Beneficiary Approver',
  BENEFICAIRY_APPROVER_OWN = 'Beneficiary Approver Own',
  FX_IAT = 'FX IAT', // Legacy
  IAT_INPUTTER = 'IAT Inputter',
  IAT_APPROVER_OWN = 'IAT Approver Own',
  IAT_APPROVER = 'IAT Approver',
  READ_ONLY = 'Read Only / Reporting',
  DRAWDOWN_INPUTTER = 'Drawdown Inputter',
  PAD_INPUTTER = 'PAD Inputter',
  PAD_APPROVER = 'PAD Approver',
  SPOT = 'Spot',
  SPOT_INPUTTER = 'Spot Inputter',
  USER_INPUTTER = 'User Inputter',
  USER_PERMISSION_MANAGER = 'User Permission Manager',
  USER_EMAIL_NOTIFICATION_MANAGER = 'User Email Notification Manager',
  CREDIT_ADMIN = 'Credit Admin',
  FIRST_PARTY_DRAWDOWN_INPUTTER = 'First Party Drawdown Inputter',
  FORWARD_INPUTTER = 'Forward Inputter',
  ALPHA_CONNECT_LOGIN = 'Alpha Connect Login'
}

export type UserRoles = UserRole[];

export type TUserRoute = {
  NoHref: boolean;
  Route: string;
  SubRoutes: TUserRoute[];
  Title: string;
};

export type TUserAccount = {
  id: string;
  name: string;
};

export type TUserAccounts = TUserAccount[];

export type TUserCredentials = {
  username: string;
  password: string;
};

export type TUser = {
  SubId: string;
  Username: string;
  Email: string;
  PhoneNumber: string;
  Name: string;
  Account?: TUserAccount;
  ContactId?: string;
  Permissions?: string[];
  MasterContactId?: string | null;
  Role?: {
    Id: number;
    Name: string;
  };
};

export type TAuthyStatus = {
  registered: boolean;
  devices: string[];
};
