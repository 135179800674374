import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  clearButton: {
    width: '20%',
    color: '#A62B23',
    backgroundColor: '#A62B231A',
    marginRight: '8px',
    '&:hover': {
      backgroundColor: '#A62B2380',
    },
  },
  updateInfoButton: {
    width: '100%',
  },
}), { name: 'beneficiaries' });

export default useStyles;
